$color-light: #ffffff;
$color-light-gray: #f2f2f2;
$color-medium-gray: #c5c5c5;
$text-gray: #5a5a5a;
$color-grey-deep: #707070;
$color-black: #000000;

$color-main: #ff6b00;
$color-main-light: #ffb682;

$color-error: #d32f2f;

$color-button: #ffa666;
