.basket__footer {
  margin-top: 2rem;
  max-width: 500px;
  margin-left: auto;
  margin-right: auto;
  overflow: auto;
}

.basket-page {
  padding: 3rem 1rem 1rem;
  max-width: 1440px;
  margin: 0 auto;
  overflow: auto;

  .basket__error {
    display: none;
  }

  .basket__loader {
    display: none;
    justify-content: center;
    align-items: center;
    margin: 2rem 0;

    i {
      font-size: 4rem;
      color: #ee742e;
    }
  }

  &.is-loading,
  &.is-empty,
  &.has-error {
    .basket__products,
    .basket__footer {
      display: none;
    }

    .basket__loader {
      display: flex;
    }
  }

  &.is-empty,
  &.has-error {
    .basket__loader {
      display: none;
    }
  }

  &.is-empty {
    .js-basket-no-products {
      display: block;
    }
  }

  &.has-error {
    .basket__error {
      display: block;
      text-align: center;
      color: $color-error;
      font-size: 1.5rem;
    }
  }
}

.basket__title,
.basket__close {
  text-align: center;
}

.basket__title {
  font-size: 2rem;
  line-height: 1.8;
}

.basket__close {
  display: flex;
  justify-content: center;
  font-size: 1.635rem;
  font-weight: bold;
  color: #ee742e;
  text-decoration: underline;
  margin: 0.5rem 0 3rem;
}

.basket__close-arrow {
  margin-right: 1rem;
}

.products__headers-wrap {
  display: none;
}

.basket__product {
  max-width: 500px;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  padding-bottom: 1rem;
  border-bottom: 1px solid #e5e5e5;

  &:not(:first-child) {
    padding-top: 1.5rem;
  }

  .product__image-wrap {
    height: 0;
    padding-bottom: 51%;
    position: relative;
    margin-bottom: 1rem;
  }

  .product__image {
    position: absolute;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .product__name {
    font-family: $font-family-bebas-neue;
    font-size: 2rem;
    line-height: 1.25;
    margin-bottom: 0.3125rem;

    a {
      color: inherit;

      &:hover {
        text-decoration: underline;
      }
    }
  }

  .product__remove {
    font-size: 1.125rem;
    line-height: 1.42;
    text-decoration-line: underline;
    color: #ee742e;
    cursor: pointer;
  }

  .product__name-col,
  .product__price-col,
  .product__counter-col,
  .product__sum-col {
    margin-bottom: 1rem;
    font-size: 1.5rem;
  }

  .product__counter-col {
    display: flex;
    align-items: center;
  }

  .product__col-name--mobile {
    margin-right: 1rem;
    font-weight: normal;
  }

  .product__price-col,
  .product__sum-col {
    font-weight: bold;
  }
}

.basket__footer-price {
  font-size: 1.5rem;
  font-weight: bold;
  line-height: 1.08;
  display: flex;
  justify-content: space-between;
  margin-bottom: 0.5rem;
}

.basket__footer-sum {
  margin-left: 1rem;
  white-space: nowrap;
}

.basket__footer-text {
  color: #9e9e9e;
  font-size: 1rem;
  line-height: 1.6;
}

.basket__payment-btn {
  font-size: 1.125rem;
  min-height: 50px;
  padding: 0.25rem 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 2rem;
  float: right;
  min-width: 270px;

  .basket__payment-btn-loader {
    display: none;
  }

  &.is-loading {
    .btn__content {
      display: none;
    }

    .basket__payment-btn-loader {
      display: block;
    }
  }
}

.js-product-template {
  display: none !important;
}

.basket__no-products {
  font-size: 1.5rem;
  text-align: center;
  line-height: 1.2;
  display: none;
}

@media (min-width: $screen-large-width) {
  .basket-page {
    padding-bottom: 2rem;
  }

  .basket__close {
    margin-top: 2rem;
    margin-bottom: 5rem;
  }

  .products__headers-wrap {
    display: flex;
    padding-bottom: 1rem;
    border-bottom: 1px solid #e5e5e5;
    margin-bottom: 2rem;
    margin-left: 1rem;
    margin-right: 1rem;
  }

  .products__header,
  .product__col {
    padding-right: 2rem;
  }

  .products__header {
    font-size: 1rem;
    font-weight: bold;
    line-height: 1.6;

    &:last-child {
      text-align: right;
    }
  }

  .products__header--name,
  .product__name-col {
    flex-grow: 1;
  }

  .products__header--price,
  .products__header--quantity,
  .products__header--sum,
  .product__price-col,
  .product__counter-col,
  .product__sum-col {
    width: 13%;
    flex-shrink: 0;
  }

  .products__header--sum,
  .product__sum-col {
    width: 10%;
    padding-right: 0;
  }

  .basket__products-list,
  .basket__footer {
    padding-left: 1rem;
    padding-right: 1rem;
  }

  .basket__product {
    max-width: none;
    display: flex;

    .product__col-name--mobile {
      display: none;
    }

    .product__counter-col {
      align-items: flex-start;
    }

    .product__sum-col {
      text-align: right;
    }

    .product__name-col {
      display: flex;
      align-items: flex-start;
    }

    .product__image-wrap {
      flex-shrink: 0;
      width: 255px;
      height: 130px;
      padding: 0;
      margin: 0 2rem 0 0;
    }
  }

  .basket__footer {
    max-width: 350px;
    margin-top: 3.8125rem;
    margin-left: 0;
    margin-right: 0;
    float: right;
  }

  .basket__products-list {
    max-height: 465px;
    overflow: auto;
  }
}
