.btn {
  font-family: $font-primary;
  font-weight: 600;
  letter-spacing: 1.2px;
  text-align: center;
  text-decoration: none;

  &:hover {
    text-decoration: none;
  }
}

.btn--primary {
  text-transform: uppercase;
  color: $color-light;
  background-color: $color-main;
  border: 3px solid $color-main;
  transition: background-color 0.3s, border-color 0.3s;

  &:hover {
    background-color: $color-button;
    border-color: $color-button;
  }
}

.btn--secondary {
  text-transform: uppercase;
  border: 3px solid $color-light;
  color: $color-light;
  background-color: transparent;
}

.btn--accent {
  text-transform: uppercase;
  border: 3px solid $color-main;
  background: none;
  color: $color-main;
}

// Sizes
.btn--sm {
  padding: 0.7rem 4rem;
  font-size: 1.1rem;
}

.btn--md {
  padding: 0.9rem 6rem;
  font-size: 1.25rem;
}

// Displays
.btn--block {
  display: block;
}

.btn--inline-block {
  display: inline-block;
}
