$richtext-mobile-margin: 15px;

.custom-page__richtext {
  font-family: $font-family-roboto;
  line-height: 1.4;
  padding: 0 $richtext-mobile-margin;
  margin: 0 auto;
  max-width: 640px;

  img {
    max-width: 100%;
    height: auto;
    object-fit: contain;
    margin: 30px auto;
    display: block;
  }

  h2 {
    text-align: center;
    font-family: $font-family-bebas-neue;
    font-size: 4rem;
    margin: 70px 0px 20px 0px;
    text-transform: uppercase;

    @include breakpoint-mobile {
      margin-bottom: 10px;
      font-size: 3rem;
    }
  }

  h3 {
    color: $color-main;
    font-size: 2.5em;
    border-left: 4px solid $color-main;
    padding-left: 10%;
    margin: 50px 0px;
    font-style: italic;

    @include breakpoint-mobile {
      font-size: 2rem;
    }
  }

  h4 {
    color: $color-main;
    font-size: 2em;
    border-left: 4px solid $color-main;
    padding-left: 10%;
    margin: 50px 0px;
    font-style: italic;

    @include breakpoint-mobile {
      font-size: 1.5rem;
    }
  }

  b {
    font-weight: bold;
  }

  p {
    margin: 1rem 0px 1rem 0px;
  }

  ul {
    margin-top: 60px;
  }

  ul li {
    display: flex;
    flex-direction: column;
    text-align: center;
    margin-bottom: 3rem;
  }
}

.custom-page__links-section.links_section-wrapper {
  margin-top: calc(2.5rem + 30px);
  padding: 0 15px;
  width: 100%;
  max-width: 640px;
  margin-left: auto;
  margin-right: auto;

  .block-linkTile {
    margin-right: 0;
    margin-left: 0;
    width: 100%;
  }
}

@media (min-width: $mobile-width) {
  .custom-page__links-section.links_section-wrapper {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-row-gap: 12px;
    grid-column-gap: 12px;

    .block-linkTile {
      margin-bottom: 0;
    }
  }
}

@media (min-width: $screen-large-width) {
  .custom-page__richtext,
  .custom-page__links-section.links_section-wrapper {
    max-width: 1440px;
    padding: 0 calc(383px + 1rem) 0 240px;
  }
}
