.page-pagination-list {
  padding: 0 1rem;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.page-pagionation__item {
  font-size: 0.875rem;
  font-weight: bold;
  line-height: 1rem;
  border: 1px solid #dfe3e8;
  margin: 0.25rem;
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;

  a {
    color: inherit;
    padding: 0.2rem;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: white;
  }

  &.is-active {
    color: #ee742e;
    border-color: #ee742e;
  }

  &.is-disabled {
    color: #c4cdd5;
  }
}
