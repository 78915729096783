.story-index {
  margin: 40px auto 0px auto;
  font-family: $font-primary;

  @include breakpoint-mobile {
    margin: 3rem auto;
  }
}

.story-index__main-title {
  text-align: center;
}

.story-index__wrapper {
  margin: 2rem 0 0 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 0 2rem;

  @include breakpoint-screen-large() {
    padding: 0;
    margin: 4rem 0 0 0;
  }
}

.story-index__card {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  margin: 0 0 2rem;

  @include breakpoint-screen-large() {
    width: calc(50% - 1rem);
    margin: 0 0 4rem;

    &:hover {
      .story-index__row-second {
        background-color: $color-main;
        color: white;
      }

      .story-index__dynamic {
        opacity: 0;
        color: $color-main;
      }

      .btn {
        background-color: white;
        color: $color-main;
      }
    }
  }
}

.story-index__row-first {
  width: 100%;
  display: flex;

  @include breakpoint-tablet {
    width: 50%;
  }
}

.story-index__row-second {
  position: relative;
  width: 100%;
  color: black;
  padding: 1.5rem 0;
  display: flex;
  flex-wrap: wrap;

  @include breakpoint-tablet {
    padding: 1.5rem 1.375rem;
    width: 50%;
  }
}

.story-index__description {
  cursor: default;
  line-height: 1.4;
}

.story-index__image {
  height: 300px;
  width: 100%;
  background-size: cover;
  background-position: center;

  @include breakpoint-tablet {
    height: auto;
  }
}

.story-index__title {
  font-family: $font-secondary;
  margin: 0 0 1rem;
}

.story-index__title-link {
  color: black;
}

.story-index__dynamic {
  opacity: 1;
  color: black;
}

.story-index__description--more {
  margin: 1rem 0 0.5rem 0;
  font-style: italic;
  display: none;
  text-align: right;
  cursor: default;
}

.story-index__button-wrapper {
  margin-top: auto;
  padding-top: 1rem;

  .btn {
    width: 100%;
    display: inline-block;

    @include breakpoint-tablet {
      width: auto;
    }
  }
}

.story-index__view-more {
  display: inline-block;
  padding: 10px 25px;
  border: 3px solid black;
  text-transform: uppercase;
  margin: 0 auto;
}

.story-index__view-more-wrapper {
  text-align: center;
  margin-top: 30px;
}
