.news-widget {
  margin: 0 auto 60px auto;
  display: flex;
  flex-direction: column;
}

.news-widget__title {
  text-transform: uppercase;
  text-align: center;
  font-family: $font-family-bebas-neue;
  margin: 3rem 0;
  font-size: 1.75rem;
  font-weight: 700;

  @include breakpoint-mobile {
    margin-bottom: 0;
  }
}

.general-image-left__description {
  width: 45%;
  margin-top: auto;

  @include breakpoint-mobile {
    width: 100%;
  }
}

.news-widget__tiles {
  max-width: $page-max-width;
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
}

.news-widget__background-element {
  @include breakpoint-tablet {
    background-repeat: no-repeat;
    background-image: linear-gradient($color-main, $color-main);
    background-size: 100% 290px;
  }
}

.news-widget__show-more {
  color: $color-main;
  border: 2px solid $color-main;
  padding: 1rem;
  text-transform: uppercase;
  font-family: $font-family-roboto;
  font-weight: 700;
  text-align: center;
  margin: 0 auto;
  display: inline-block;
  justify-content: center;

  &:hover {
    color: white;
    background-color: $color-main;
  }
}
