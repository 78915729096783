.popup-wrap {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1000;
  align-items: center;
  padding: 1rem;

  &.is-open {
    display: flex;
  }
}

.popup-overlay {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: #e5e5e5;
  opacity: 0.5;
}

.popup {
  max-width: 1000px;
  width: 100%;
  margin: 0 auto;
  background: white;
  padding: 1rem 2rem 1.5rem;
  border: 1px solid #e5e5e5;
  border-radius: 8px;
  position: relative;

  .popup__close {
    position: absolute;
    top: 0;
    right: 0;
    border: 0;
    background: none;
    padding: 0.75rem 1rem;
    font-size: 2rem;
    font-weight: bold;
    line-height: 0.6;
    cursor: pointer;
  }
}

.added-to-basket-popup {
  .popup {
    max-width: 540px;
    padding-left: 1rem;
    padding-right: 1rem;
    padding-bottom: 0.5rem;
  }

  .popup__image-wrap {
    width: 100%;
    height: 0;
    position: relative;
    padding-bottom: 60%;
  }

  .popup__image {
    position: absolute;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center center;
  }

  .popup__title {
    font-size: 2.5rem;
    font-family: $font-family-bebas-neue;
    margin: 1.5rem 0 1rem;
  }

  .popup__product-name {
    font-family: $font-family-bebas-neue;
    font-size: 2rem;
    margin-bottom: 0.75rem;
  }

  .btn {
    margin: 1rem 0;
    padding: 1rem;
    min-height: 3.125rem;
    width: 100%;
    display: block;
  }

  .popup__quantity-info {
    margin: 1rem 0 0;
  }

  .popup__price-info {
    display: flex;
    font-size: 1.5rem;
  }

  .popup__price {
    font-weight: bold;
    color: #ee742e;
  }

  .product__actions {
    margin-top: 1.75rem;
  }

  .popup__quantity {
    margin-left: 0.5rem;
  }

  .popup__title--desktop {
    display: none;
  }
}

.popup-mw-redirect {
  .popup__actions {
    display: flex;
    flex-direction: column;
    margin-top: 1.5rem;
  }

  .popup {
    padding-top: 1.5rem;
    font-family: $font-family-roboto;
    text-transform: none;
    max-width: 440px;
  }

  .popup__info {
    line-height: 1.3;
    font-size: 18px;
  }

  .btn {
    min-height: 42px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 1rem;

    &:last-child {
      margin-bottom: 0;
    }
  }
}

@media (min-width: $mobile-width) {
  .added-to-basket-popup {
    .product__actions {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      margin-top: 1rem;
    }

    .btn {
      width: auto;
    }

    .popup__product-name,
    .popup__title {
      margin-bottom: 1rem;
    }

    .popup__title {
      margin-top: 2rem;
    }
  }
}

@media (min-width: $screen-large-width) {
  .added-to-basket-popup {
    padding: 2rem;

    .popup {
      max-width: 1180px;
      padding: 3rem 7.875rem 5rem 7.125rem;
    }

    .popup__title {
      display: none;
    }

    .popup__title--desktop {
      display: block;
      font-family: $font-family-bebas-neue;
      font-size: 3rem;
      text-align: center;
      line-height: 1.2;
      margin-bottom: 2rem;
    }

    .popup__content {
      display: flex;
    }

    .popup__image-wrap {
      max-width: 540px;
      padding-bottom: 45%;
      margin-right: 3.125rem;
      flex-shrink: 0;
    }

    .popup__main {
      display: flex;
      flex-direction: column;
      width: 100%;
    }

    .popup__main-top {
      flex-grow: 1;
    }

    .product__actions {
      display: block;
      width: 100%;
      margin-top: 2rem;

      .btn {
        width: 100%;
        margin: 0;
        font-size: 1.125rem;

        &:first-child {
          margin-bottom: 1.6875rem;
        }
      }
    }

    .popup__product-name {
      margin: 2rem 0 3rem;
      font-size: 2.5rem;
      line-height: 1.1;
    }

    .popup__price-info {
      font-size: 2rem;
    }
  }
}
