.news__header {
  max-width: $page-max-width;
  width: 100%;
  min-height: 400px;
  height: auto;
  margin: 0 auto;
  position: relative;

  @include breakpoint-mobile {
    min-height: unset;
  }

  @include breakpoint-tablet {
    display: flex;
    align-items: center;
    padding: 3rem 0;
  }

  @include print {
    display: none;
  }
}

.news__image {
  height: 300px;
  width: 100%;
  background-size: cover;
  background-position: center;

  @include breakpoint-tablet {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
  }
}

.news-texts {
  line-height: 1.3;
  width: 42%;
  margin: 0 auto 4rem auto;

  @include breakpoint-screen-small {
    width: 70%;
  }

  @include breakpoint-mobile {
    width: 90%;
    margin-bottom: 2rem;
  }
}

.news__date {
  font-size: 1rem;
  font-family: $font-family-roboto;
  margin-top: 4rem;
  color: $color-grey-deep;
}
.news__title {
  font-size: 3em;
  font-family: $font-family-bebas-neue;
  margin-top: 2.5rem;
}
.news__lead {
  margin: 2.5rem 0 1.5rem 0;
  font-size: 1rem;
  font-family: $font-family-roboto;
  font-weight: 800;
}

.news__content {
  @include breakpoint-mobile {
    width: calc(100% - (#{$richtext-mobile-margin} * 2));
    margin: 0px $richtext-mobile-margin;
  }

  img {
    max-width: 100%;
    height: auto;
    object-fit: contain;
    margin: 30px auto;
    display: block;
  }

  h2 {
    text-align: center;
    font-family: $font-family-bebas-neue;
    font-size: 4rem;
    margin: 80px 0px;
    text-transform: uppercase;

    @include breakpoint-mobile {
      margin: 40px 0px 10px 0px;
      font-size: 3rem;
    }
  }

  h3 {
    color: $color-main;
    font-size: 2.5em;
    border-left: 4px solid $color-main;
    padding-left: 10%;
    margin: 50px 0px;
    font-style: italic;
    @include breakpoint-mobile {
      font-size: 2rem;
    }
  }

  h4 {
    @include breakpoint-mobile {
      font-size: 1.5rem;
    }
  }

  b {
    font-weight: bold;
  }

  p {
    margin: 16px 0px;
  }
}
