.team-member {
  max-width: 600px;
  margin: 10rem auto;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.team-member__image {
  width: 200px;
  height: 200px;
  border-radius: 100px;
  object-fit: cover;
}

.team-member__name {
  margin: 2rem 0;
  text-align: center;
  font-family: $font-family-bebas-neue;
  font-size: 2rem;
}

.team-member__description {
  text-align: center;
  font-size: 1.5rem;
}
