.main-page-gratification {
  max-width: $page-max-width;
  margin: 5rem auto;
}

.main-page-gratification__title {
  text-transform: uppercase;
  text-align: center;
  font-family: $font-family-bebas-neue;
  margin-bottom: 3rem;
  font-size: 1.75rem;
  font-weight: 700;

  @include breakpoint-mobile {
    margin-bottom: 0;
  }
}

.general-image-left__description {
  width: 45%;
  margin-top: auto;

  @include breakpoint-mobile {
    width: 100%;
  }
}

.general-image-left__description-title {
  text-transform: uppercase;
  font-family: $font-family-bebas-neue;
  margin-bottom: 2rem;
  font-size: 1.75rem;

  @include breakpoint-mobile {
    margin-bottom: 1rem;
  }
}

.general-image-left__description-text {
  margin-bottom: 2rem;
  max-width: 70%;
  margin-right: auto;
  line-height: 1.4;

  @include breakpoint-mobile {
    margin-bottom: 1rem;
    max-width: 95%;
    margin-left: auto;
  }
}

.general-image-left__button {
  display: inline-block;
  text-transform: uppercase;
  text-align: center;
  color: #fff;
  font-family: $font-family-roboto;
  font-weight: 700;
  padding: 0.5rem 1rem;
  background-color: $color-main;
  font-size: 100%;

  @include breakpoint-mobile {
    padding: 0.75rem 1rem;
    margin-top: 1rem;
  }
}

.general-image-right__image {
  width: 30%;
  margin-right: 15%;
  object-fit: contain;

  @include breakpoint-mobile {
    width: 80%;
    margin: 1rem auto;
  }
}

.general-image-left {
  padding: 1.5rem 0px 2rem 0px;
  color: black;
  display: flex;
  justify-content: space-between;
  align-content: flex-end;
  margin-bottom: 4rem;
  flex-wrap: wrap;

  &:hover {
    background-color: $color-main;

    .general-image-left__description {
      color: white;
    }

    .general-image-left__button {
      color: $color-main;
      background-color: white;
    }
  }

  @include breakpoint-mobile {
    text-align: center;
    padding: 1rem 0px;
    margin-bottom: 2rem;
  }
}

.general-image-left__image {
  width: 45%;
  object-fit: contain;

  @include breakpoint-mobile {
    width: 80%;
    margin: 1rem auto;
  }
}

.general-image-right__description {
  width: 45%;
  text-align: right;
  margin-top: auto;

  @include breakpoint-mobile {
    width: 100%;
    text-align: center;
    order: 2;
  }
}

.general-image-right__description-title {
  text-transform: uppercase;
  font-family: $font-family-bebas-neue;
  margin-bottom: 2rem;
  font-size: 1.75rem;

  @include breakpoint-mobile {
    margin-bottom: 1rem;
  }
}

.general-image-right__description-text {
  margin-bottom: 2rem;
  max-width: 70%;
  margin-left: auto;
  line-height: 1.4;

  @include breakpoint-mobile {
    margin-bottom: 1rem;
    max-width: 95%;
    margin-right: auto;
  }
}

.general-image-right__button {
  display: inline-block;
  text-transform: uppercase;
  text-align: center;
  color: $color-light;
  font-family: $font-family-roboto;
  font-weight: 700;
  padding: 0.5rem 1rem;
  background-color: $color-main;
  font-size: 100%;

  @include breakpoint-mobile {
    padding: 0.75rem 1rem;
    margin-top: 1rem;
  }
}

.general-image-right {
  padding: 1.5rem 0px 2rem 0px;
  color: black;
  display: flex;
  justify-content: space-between;
  align-content: flex-end;
  flex-wrap: wrap;

  &:hover {
    background-color: $color-main;

    .general-image-right__description {
      color: white;
    }

    .general-image-right__button {
      color: $color-main;
      background-color: white;
    }
  }

  @include breakpoint-mobile {
    text-align: center;
    padding: 1rem 0px;
  }
}
