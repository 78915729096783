// base
@use "sass:math";

@import "@splidejs/splide/dist/css/splide.min.css";

@import "reset-css";
@import "./fonts.scss";
@import "./global.scss";
@import "./headings.scss";

// shared
@import "./variables.scss";
@import "./mixins-breakpoints.scss";
@import "./mixins-other.scss";
@import "./colors.scss";
@import "./grid/index";
@import "~animate.css/animate.min.css";
@import "embed.scss";

// layout modules
@import "./paybox.scss";
@import "./topbar.scss";
@import "./footer.scss";
@import "./cookies.scss";
@import "./button.scss";

// modules
@import "./header-image-payment.scss";
@import "./custom-page.scss";
@import "./gratification-ticket.scss";
@import "./gratification-passport.scss";
@import "./carousel.scss";
@import "./how-to-help.scss";
@import "./story-index.scss";
@import "./about-us.scss";
@import "./about-us-page.scss";
@import "./story.scss";
@import "./right-image-block.scss";
@import "./general-image.scss";
@import "./payment.scss";
@import "./return.scss";
@import "./privacy-policy.scss";
@import "./friends.scss";
@import "./team-members.scss";
@import "./team-member.scss";
@import "./newsletter.scss";
@import "./news-tile.scss";
@import "./news-widget.scss";
@import "./news-index-page.scss";
@import "./news.scss";
@import "./rules.scss";
@import "./form-wrapper.scss";
@import "./radio-button.scss";
@import "./other-activities.scss";
@import "./our-achievements.scss";
@import "./slider.scss";
@import "./products.scss";
@import "./basket.scss";
@import "./popups.scss";
@import "./order-payment-form.scss";
@import "./inputs.scss";
@import "./pagination.scss";
@import "./tippy.scss";
@import "./links-section.scss";
