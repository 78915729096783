.submit-wrapper,
.paypal-wrapper,
.recurring-wrapper {
  margin-top: 50px;
  width: math.div($page-max-width, 2);
  border-radius: 10px;
  margin-left: auto;
  margin-right: auto;
  padding: 50px;
}

.form-wrapper {
  box-shadow: $box-shadow;
  border-radius: 0px;
  background-color: white;
  box-sizing: border-box;
  transform: translateY(-135px);

  @include breakpoint-mobile {
    width: auto;
    padding: 20px;
  }

  @include print {
    display: none;
  }
}

.payment__title {
  font-size: 3rem;
  font-family: $font-family-bebas-neue;
  margin-bottom: 1rem;
  text-transform: uppercase;
}

.payment__subtitle {
  font-size: 2rem;
  font-family: $font-family-bebas-neue;
  margin-bottom: 1rem;
  text-transform: uppercase;
}

.payment__whom {
  font-size: 2rem;
  text-transform: uppercase;
  font-family: $font-family-bebas-neue;
  color: $text-gray;
  margin-bottom: 3rem;
}

.payment__buttons {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: 1rem 0;

  @include breakpoint-mobile {
    display: block;
  }
}

.payment__input {
  margin-bottom: 1rem;

  input {
    font-size: 1.5rem;
    font-family: $font-family-bebas-neue;
    border-bottom: 2px solid;
    border-top: 0px;
    border-left: 0px;
    border-right: 0px;
    line-height: 1.5;
    text-transform: uppercase;
    width: 100%;
  }

  input[type="text"]:focus {
    background-color: $color-main;
    color: white;
    outline: none;
  }

  ::placeholder {
    color: gray;
    line-height: 1.5;
  }
}

.payment__input-other {
  @extend .payment__input;

  input {
    font-family: $font-family-roboto;
    font-size: 1.25rem;
  }

  ::placeholder {
    color: black;
  }
}

.payment__data {
  margin-top: 4rem;
}

#payment__pay-button {
  text-transform: uppercase;
  font-size: 1.5rem;
  font-family: $font-family-roboto;
  margin-left: auto;
  width: 150px;
  display: block;
  margin-top: 2rem;
}

.payment__passport {
  margin-top: 50px;

  &.is-payment--hidden {
    display: none;
  }
}

.payment__passport-checkbox {
  &.is-payment--hidden {
    display: none;
  }
}

.payment__passport-text {
  margin: 25px 0px 50px 0px;
}

.payment__legal {
  margin-bottom: 1.5rem;
  font-family: $font-family-roboto;
  font-size: 0.8rem;
  line-height: 1.5;
}

.payment__legal-with-checkbox {
  @extend .payment__legal;

  padding-left: 20px;
}

.payment__checkbox {
  position: absolute;
  opacity: 0;
  transform: translateX(-24px);
  cursor: pointer;
}

.payment__error-box {
  &.payment__error-box--display {
    border: 2px solid red;
    padding: 14px;
    margin: 20px -16px;
  }
}

.payment__error-message {
  color: red;
  text-transform: uppercase;
  font-family: $font-family-bebas-neue;
  position: absolute;
  background: white;
  padding: 0 11px;
  transform: translateY(50%);
  display: none;

  &.is-visible {
    display: inline-block;
  }
}

.payment__error--is-hidden {
  display: none;
}

.payment__button-submit {
  width: 150px;
  text-align: center;
}

.payment__nav-buttons {
  text-transform: uppercase;
  font-family: $font-family-roboto;
  max-width: math.div($page-max-width, 2);
  margin: 50px auto;
  text-align: center;
  display: flex;
}

.payment__button-next {
  transform: translateY(-135px);
  width: 50%;
  margin-left: auto;
  height: 41px;
  text-transform: uppercase;
  cursor: pointer;
  font-size: 1rem;
  border: 3px solid;
  padding: 0.5rem 1rem;
  border-color: $color-main;
  color: $color-main;
  background: white;
  font-weight: bold;
}

.payment__button-fullwidth {
  width: 100%;
}

.paypal-text {
  font-family: $font-family-lato;
  font-size: 1.5rem;
  margin-bottom: 2rem;
}

.recurring-text {
  font-size: 2rem;
  margin-bottom: 2rem;
  font-family: $font-family-lato;
}

.reccuring-button {
  font-size: 1.5rem;
  font-family: $font-family-lato;
}

// for django-generated form errors
.errorlist {
  color: red;
}

.payment__header {
  max-width: $page-max-width;
  margin: 0px auto;

  img {
    width: 100%;
    object-fit: cover;
  }
}

.payment__header-image {
  width: 100%;
  object-fit: cover;
}

.payment__benefits {
  display: flex;
  margin-bottom: 5rem;

  @include breakpoint-mobile {
    display: block;
  }
}

.payment__benefits-left {
  width: 50%;

  @include breakpoint-mobile {
    width: 100%;
  }
}

.payment__benefits-right {
  width: 50%;

  @include breakpoint-mobile {
    width: 100%;
  }
}

.payment__benefits-head {
  font-family: $font-family-bebas-neue;
  font-size: 1.5rem;
}

.payment__benefits-item {
  padding-left: 10px;
  margin-bottom: 0.5rem;
  margin-right: 5px;
  line-height: 1.5;
  font-size: 0.9rem;
  list-style-type: none;
}

li.payment__benefits-item::before {
  content: "\25A0";
  color: $color-main;
  font-size: 1rem;
  font-weight: bold;
  display: inline-block;
  width: 1rem;
  margin-left: -0.8rem;
}

.payment__input.payment__input--orange {
  input {
    border-color: $color-main;
  }
}

.payment__checkbox-label {
  display: block;
  position: relative;
  cursor: pointer;
  clear: both;
  padding-left: 20px;
}

.payment__checkbox-label-bold {
  @extend .payment__checkbox-label;

  font-weight: bold;
}

.payment__checkbox-custom {
  position: absolute;
  top: 0px;
  left: -22px;
  height: 18px;
  width: 18px;
  background-color: transparent;
  transition: all 0.3s ease-out;
  border: 2px solid #000000;
}

.payment__checkbox-custom--orange {
  @extend .payment__checkbox-custom;

  border: 2px solid $color-main;
}

.payment__checkbox-label input:checked ~ .payment__checkbox-custom {
  background-color: $color-main;
  transform: rotate(0deg) scale(1);
  opacity: 1;
  border: 2px solid $color-main;
}

.payment__error-box-photo-upload {
  margin-bottom: 3rem;
  display: flex;
  align-items: center;
  position: relative;

  .payment__error-message {
    bottom: 0;
    right: 0;
  }
}

.payment__ajax-error-message {
  font-size: 2rem;
  font-family: $font-family-antonio;
}

.payment__image-upload-input {
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
}

.payment__image-load-label {
  text-transform: uppercase;
  border: 2px solid black;
  font-family: $font-family-roboto;
  padding: 1rem 1.5rem;
  font-weight: 600;
  letter-spacing: 0.3rem;
  flex-shrink: 0;

  &:hover {
    color: white;
    background-color: $color-main;
    border-color: $color-main;
  }
}

.payment__image-upload-status {
  font-size: $font-family-roboto;
  display: inline;
  margin-left: 3rem;

  @include breakpoint-mobile {
    display: block;
    margin-top: 2rem;
    margin-left: 0;
  }
}

.payment__summary {
  .errorlist li {
    padding-bottom: 10px;
  }
}

.summary__content {
  font-family: $font-family-roboto;
  line-height: 1.5;
  margin-bottom: 1rem;

  &:last-of-type {
    margin-bottom: 3rem;
  }

  em {
    font-style: italic;
  }

  b {
    font-weight: bold;
  }
}

.summary__header {
  color: #60605f;
  line-height: 1.2;
}
