$paybox-width: 350px;

.paybox-container {
  position: relative;
}

.paybox {
  font-family: $font-family-roboto;
  will-change: transform;

  @media (max-width: #{$screen-large-width - 1}) {
    margin: 2rem 0;
    position: static !important;
    transform: translate(0) !important;
  }

  @include breakpoint-mobile {
    margin: 0;
  }

  @include breakpoint-screen-large() {
    width: calc(#{$paybox-width} - 3px);
    position: absolute;
    right: 1rem;
    z-index: 10;
  }
}

.paybox__content {
  padding: 24px;
  background-color: white;
}

.paybox__title {
  font-size: 3rem;
  font-family: $font-family-bebas-neue;
  color: $color-main;
  text-transform: uppercase;
  margin-bottom: 1.5rem;
}

.paybox__lead {
  font-size: 1.3rem;
  font-weight: bolder;
  margin-bottom: 1.5rem;
  line-height: 1.4;
}

.paybox__text {
  font-weight: regular;
  margin-bottom: 1.5rem;
  line-height: 1.4;
}

.paybox__buttons-row {
  display: flex;
}

.paybox__radio-button-button {
  background-color: white;
  width: calc(100% - 10px);
  font-weight: 800;
  border: 3px solid black;
  color: black;
  margin: 5px;
  padding: 0.5rem;
  display: block;
  text-align: center;
  text-transform: uppercase;
  font-size: 0.8rem;
}

.paybox__radio-button {
  cursor: pointer;

  // adjust all custom radios that are preceeded by a checked input
  .paybox__radio-button-input:checked + & {
    background-color: $color-main;
    border-color: $color-main;
    color: white;
  }
}

// hide default radio
.paybox__radio-button-input {
  display: none;
}

.paybox__radio-button-halfwidth {
  @extend .paybox__radio-button;
  width: 50%;
}

.paybox__radio-button-fullwidth {
  @extend .paybox__radio-button;
  width: 100%;
}

.paybox__submit-buttons {
  display: flex;
}

.paybox__submit {
  cursor: pointer;
  font-family: $font-family-roboto;
  font-weight: bold;
  padding: 0.5rem;
  font-size: 0.8rem;
  width: 50%;
  margin: 5px;
  text-transform: uppercase;
}

.paybox__submit-monthly {
  border: 1px solid $color-main;
  background-color: $color-main;
  color: white;
}
.paybox__submit-once {
  border: 3px solid $color-main;
  background-color: white;
  color: $color-main;
}

.paybox__progress-bar {
  background-color: white;
  margin-top: 1rem;
  position: relative;

  @include breakpoint-mobile {
    margin: 1rem 24px 0 24px;
  }
}

.paybox__progress-bar-text {
  padding: 19px 24px;
  font-size: 0.8rem;
}

.paybox__progress-bar-progress {
  padding: 19px 0px;
  background-color: $color-main;
  max-width: 100%;
  transition: all 500ms;
  text-align: center;
}

.paybox__progress-bar-numbers {
  width: 100%;
  text-align: center;
  position: absolute;
  color: white;
  font-size: 1rem;
  right: 50%;
  top: 50%;
  transform: translate(50%, -50%);
}

.paybox__progress-bar-heart-wrapper {
  position: absolute;
  right: 50%;
  top: 0%;
  transform: translate(50%, -60%);
}

.paybox__progress-bar-heart {
  color: $color-main;
  font-size: 2rem;
}

.paybox__progress-bar-bar {
  background-color: $color-main-light;
  position: relative;
}
