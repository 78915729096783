.links_section-wrapper {
  max-width: $page-max-width;
  display: flex;
  justify-content: left;
  width: 98%;
  margin: 30px auto;
  flex-wrap: wrap;
}

.links-section__tile-arrow {
  border: 5px solid $color-main;
  width: 75px;
  height: 75px;
  text-align: center;
  border-radius: 50px;
  padding-left: 5px;
  padding-top: 18px;
  transform: scale(0.5);
  flex-shrink: 0;
}

.links-section__tile {
  width: 100%;
  padding: 10px 30px;
  display: flex;
  justify-content: space-between;
  color: $color-main;

  &:hover {
    color: #fff;

    .links-section__tile-arrow {
      border-color: #fff;
    }
  }

  @include breakpoint-mobile {
    padding: 0px;
  }
}

.links-section__tile-text {
  display: flex;
  align-items: center;
}

.block-linkTile {
  margin-right: 4px;
  width: calc(25% - 3px);
  border: 3px solid $color-main;
  color: $color-main;
  font-family: $font-family-bebas-neue;
  font-size: 1.5rem;
  margin-bottom: 2.5rem;
  display: flex;
  align-items: center;
  justify-content: space-between;

  &:nth-child(4) {
    margin-right: 0px;

    @include breakpoint-mobile {
      margin-right: auto;
    }
  }

  &:hover {
    background-color: $color-main;
    .about-us__links-tile-arrow {
      border-color: #fff;
    }
  }

  @include breakpoint-mobile {
    width: 90%;
    margin: 0 auto 1rem auto;
    font-size: 1.5rem;
    padding: 5px 10px;
  }
}
