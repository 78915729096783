.privacy-policy {
  max-width: $page-max-width;
  width: 75%;
  margin: 50px auto;

  @include breakpoint-mobile {
    width: 90%;
  }
}

.privacy-policy__main-title {
  text-align: center;
  font-size: 2rem;
  margin-top: 2rem;
  margin-bottom: 2rem;
}

.privacy-policy__lead {
  font-size: 1rem;
  line-height: 1.5;
  text-align: left;
  margin-bottom: 2rem;
  font-family: $font-family-roboto;
}

.privacy-policy__section {
  margin-bottom: 3rem;
}

.privacy-policy__section-title {
  font-size: 1.5rem;
  font-weight: 500;
  text-align: center;
  margin-bottom: 1rem;
}

.privacy-policy__section p {
  line-height: 1.3;
  margin-bottom: 0.5rem;
}
