.app-select {
  width: 100%;
  max-width: 320px;
  position: relative;

  select {
    /* Reset Select */
    appearance: none;
    box-shadow: none;
    background: none;
    outline: 0;
    padding: 1rem 2rem 1rem 1rem;
    font-size: 1.125rem;
    border: 2px solid $color-black;
    width: 100%;

    &::-ms-expand {
      display: none;
    }
  }

  &:after {
    content: "";
    background: url("@/images/icon_chevron.svg");
    position: absolute;
    top: 50%;
    right: 1rem;
    transform: translateY(-50%);
    width: 12px;
    height: 8px;
  }
}

.app-search-input {
  border: 2px solid $color-black;
  font-size: 1.125rem;
  max-width: 320px;
  width: 100%;
  display: flex;
  align-items: stretch;

  input {
    border: 0;
    outline: 0;
    background: white;
    padding: 1rem;
    width: 100%;
    font-size: 1.125rem;

    &::placeholder {
      font-size: 1.125rem;
    }
  }

  .app-search-input__btn {
    border: 0;
    outline: 0;
    background: white;
    display: flex;
    align-items: center;
    padding: 1rem;
  }
}
