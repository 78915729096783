.form-wrapper {
  margin-top: 50px;
  width: math.div($page-max-width, 2);
  margin-left: auto;
  margin-right: auto;
  padding: 50px;
  box-shadow: $box-shadow;
  border-radius: 0px;
  background-color: white;
  box-sizing: border-box;
  transform: translateY(-135px);

  @include breakpoint-mobile {
    width: auto;
  }
}
