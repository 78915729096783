// all products page
.products-page {
  max-width: $page-max-width;
  margin: 0 auto;
  padding-bottom: 4.5rem;

  .no-products-info {
    text-align: center;
    font-size: 1.5rem;
    margin: 1.5rem 0;
  }

  .products__filters-form {
    margin-bottom: 3rem;

    .filter__item {
      margin-bottom: 2rem;
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    .filter__item-label {
      font-weight: bold;
      font-size: 1.125rem;
      margin-bottom: 1rem;
    }
  }
}

.products__banner {
  color: white;
  min-height: 400px;
  background-size: cover;
  background-position: center center;
  padding: 2rem 1.5rem 3rem;
  text-align: center;

  .banner__title {
    font-family: $font-family-bebas-neue;
    font-size: 4rem;
    margin-bottom: 1rem;
  }

  .banner__text {
    font-size: 1.125rem;
    line-height: 1.4;
    max-width: 600px;
    margin: 0 auto;
  }
}

// products list
.products__section {
  padding: 2.5rem 1.5rem 0;
}

.products-list {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin: 0 -12px;
}

.products-list__item-wrap {
  width: 100%;
  max-width: 256px;
  margin-left: 12px;
  margin-right: 12px;
  margin-bottom: 3.75rem;
  display: flex;

  .item__img {
    position: absolute;
    height: 100%;
    width: 100%;
    object-fit: contain;
    object-position: center center;
  }

  .item__bottom {
    font-size: 1.125rem;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
  }

  .item__bottom-link {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    color: inherit;
  }

  .item__text-icon {
    width: 24px;
    margin-right: 0.3rem;
    flex-shrink: 0;
    position: relative;
    top: 2px;
  }

  .item__text {
    flex-grow: 1;

    .item__text-icon {
      path {
        fill: #ee742e;
      }
    }
  }

  .item__title {
    text-transform: uppercase;
    font-weight: 700;
    margin-top: 1.875rem;
  }

  .item__category,
  .item__story {
    font-size: 1.25rem;
    line-height: 1.3;
    margin: 0.55rem 0 0.2rem;
    display: flex;
    font-family: $font-family-bebas-neue;
  }

  .item__desc {
    font-size: 1rem;
    line-height: 1.37;
    letter-spacing: 0.3px;
    margin: 1.5rem 0 0.5rem;
  }

  .item__story {
    margin-top: 1.3rem;
  }

  .item__price {
    margin-top: 1rem;
    margin-bottom: 2rem;

    b {
      font-weight: 700;
    }
  }

  .item__link {
    color: #ee742e;
    font-size: 0.875rem;
    font-weight: bold;
    line-height: 1.29;
    text-align: center;
    margin-bottom: 2rem;
  }

  .item__add-button {
    border-radius: 0;
    padding: 1rem;
    min-height: 3.125rem;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.125rem;
    font-weight: 700;
    outline: none;
  }

  .item__btn {
    border: 2px solid white;
    padding: 0.8125rem 0.75rem;
    text-transform: uppercase;
    font-weight: 700;
    font-size: 1.125rem;
    position: relative;
    background: none;
    color: white;
    outline: none;
  }

  .item__img-wrap {
    height: 0;
    width: 100%;
    padding-bottom: 157%;
    position: relative;
    display: block;

    .item__overlay {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      color: white;
      opacity: 0;
      transition: opacity 0.4s;

      &::before {
        content: "";
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        background: #ee742e;
        opacity: 0.8;
      }

      .item__overlay-content {
        position: relative;
        height: 100%;
        padding: 0 1.5rem;
      }

      .item__story {
        margin-top: 0;
        padding-top: 2rem;
      }

      .item__text-icon {
        margin-right: 0.75rem;
      }

      .item__overlay-text {
        margin-bottom: 1rem;
        max-height: 40%;
        overflow: hidden;
        display: none;
      }

      .item__btn {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        white-space: nowrap;
      }
    }
  }
}

.products-list__item {
  width: 100%;
  display: flex;
  flex-direction: column;

  &:hover {
    .item__img-wrap .item__overlay {
      opacity: 1;
    }
  }
}

.products__section-title {
  text-align: center;
  margin-bottom: 2rem;
  font-size: 2rem;
}

// product page
.product-page {
  max-width: $page-max-width;
  margin: 0 auto;
  padding-bottom: 4rem;

  img {
    max-width: 100%;
  }

  .slide__img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
  }

  .product-slider-wrap {
    margin-bottom: 1.5rem;
    display: flex;
    flex-direction: column;
    max-width: 636px;
    margin-left: auto;
    margin-right: auto;

    .splide__arrow {
      background: white;
    }

    .splide__slide {
      border: 0 !important;
    }

    .slider-thumbnails {
      padding-top: 1.75rem;
    }
  }

  .product__title {
    text-transform: uppercase;
    font-family: $font-family-bebas-neue;
    font-size: 2rem;
    text-align: center;
    line-height: 1.28;
  }

  .product__counter-wrap {
    display: flex;
    max-width: 240px;
    margin-left: auto;
    margin-right: auto;
    align-items: flex-end;
    justify-content: space-between;
    margin-bottom: 1.5rem;
  }

  .product-info-wrap {
    padding: 0 1rem;
  }

  .product__price {
    color: #ee742e;
    font-weight: 600;
    font-size: 1.25rem;
    padding-bottom: 0.68rem;
  }

  .product__actions {
    display: flex;
    justify-content: center;
  }

  .product__action {
    max-width: 350px;
    width: 100%;
    min-height: 42px;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    text-transform: uppercase;
    border: 0;
    font-size: 1.125rem;
    font-weight: 700;
    margin-bottom: 1.5rem;
  }

  .product__action-icon {
    margin-right: 0.75rem;
  }

  .product__text {
    max-width: 444px;
    margin: 0 auto;

    b {
      font-weight: 700;
    }
  }

  .product__category,
  .product__story {
    font-weight: 500;
    margin-bottom: 1rem;
    line-height: 1.3;

    a {
      color: inherit;

      &:hover {
        text-decoration: underline;
      }
    }
  }

  .product__short-desc {
    margin-bottom: 1rem;
  }

  .product__short-desc,
  .product__desc {
    line-height: 1.22;
    font-size: 1rem;
  }

  .product__desc {
    h2 {
      font-size: 2rem;
      margin: 1rem 0;
    }

    h3 {
      font-size: 1.5rem;
      margin: 1rem 0;
    }

    i {
      font-style: italic;
    }
  }

  .related-products {
    margin-top: 3rem;
    padding: 0 1rem;
  }

  .related-products__title {
    font-family: $font-family-bebas-neue;
    font-size: 2rem;
    line-height: 1.25;
    margin-bottom: 3rem;
    text-align: center;
  }
}

.product__counter {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.product__counter-actions {
  border: 1px solid #ee742e;
  min-height: 42px;
  min-width: 105px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.25rem;
  position: relative;
  padding: 0 1.75rem;

  button {
    border: 0;
    padding: 0;
    cursor: pointer;
    background: none;
    outline: none;
  }

  .add,
  .remove {
    position: absolute;
    top: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 1.25rem;
    box-sizing: content-box;
    color: #ee742e;

    &.is-disabled {
      color: #a7a7a7;
    }
  }

  .remove {
    padding-left: 0.5rem;
    left: 0;
    font-size: 1.5rem;

    span {
      position: relative;
      bottom: 2px;
    }
  }

  .add {
    padding-right: 0.5rem;
    right: 0;
  }
}

.product__counter-label {
  font-size: 1rem;
  line-height: 2;
  margin-bottom: 0.25rem;
}

.home-page-products {
  max-width: calc(1109px + 4rem);
  margin-left: auto;
  margin-right: auto;
  margin-top: 2rem;
  margin-bottom: 4.5rem;

  .products-list-wrap {
    margin: 0 12px;
  }
}

.home-page-products__top {
  padding: 0 2rem;
}

.home-page-products__title {
  font-size: 2rem;
  font-family: $font-family-bebas-neue;
  text-align: center;
  margin-bottom: 1.5rem;
  line-height: 1.25;
}

.home-page-products__subtitle {
  font-size: 1.125rem;
  line-height: 1.22;
  margin-bottom: 2.625rem;
  letter-spacing: 0.3px;
}

.home-page-products__banner {
  position: relative;
  padding: 1.5625rem 1rem 1rem;
  text-align: center;
  max-width: 1109px;
  margin-left: auto;
  margin-right: auto;

  .banner__img {
    position: absolute;
    display: block;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
  }

  .banner__content {
    position: relative;
  }

  .banner__text {
    color: white;
    text-transform: uppercase;
    font-family: $font-family-bebas-neue;
    font-size: 2rem;
    line-height: 1.25;
  }

  .banner__link {
    padding: 0.25rem 2rem;
    max-width: 417px;
    width: 100%;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    text-transform: uppercase;
    color: #ee742e;
    min-height: 50px;
    background: white;
    border: 2px solid #ee742e;
    font-weight: bold;
    margin-top: 0.5rem;
    margin-left: auto;
    margin-right: auto;
    line-height: 1.25;
    transition: all 0.3s;

    &:hover {
      color: white;
      background: #ee742e;
    }
  }
}

.story-page__related-products {
  max-width: 640px;
  padding: 0 15px;
  margin: 2rem auto;

  .related-products__title {
    font-size: 3rem;
    letter-spacing: 0.1px;
    text-transform: uppercase;
    font-family: $font-family-bebas-neue;
    color: #ee742e;
    margin-bottom: 1.25rem;
  }

  .related-products__description {
    font-size: 0.9375rem;
    line-height: 1.27;
  }

  .related-products__products {
    margin-top: 2rem;

    .related-product {
      border-top: 2px solid #ee742e;
      padding-top: 1.375rem;
      padding-bottom: 2rem;

      &:last-child {
        padding-bottom: 0;
      }
    }

    .related-product__img-wrap {
      position: relative;
      padding-bottom: 51%;
      margin-bottom: 2rem;

      .item__overlay {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        color: white;
        opacity: 0;
        transition: opacity 0.4s;

        &::before {
          content: "";
          position: absolute;
          top: 0;
          bottom: 0;
          left: 0;
          right: 0;
          background: #ee742e;
          opacity: 0.8;
        }

        .item__overlay-content {
          position: relative;
          height: 100%;
          padding: 0 1.5rem;
        }

        .item__btn {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          white-space: nowrap;
          border: 2px solid white;
          padding: 0.8125rem 0.75rem;
          text-transform: uppercase;
          font-weight: 700;
          font-size: 1.125rem;
          background: none;
          color: white;
          outline: none;
        }
      }
    }

    .related-product__img {
      position: absolute;
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center;
    }

    .related-product__title {
      font-weight: bold;
      font-size: 1.375rem;
      line-height: 1.09;
      margin-bottom: 2rem;
    }

    .related-product__price {
      margin-bottom: 2rem;
      font-size: 1.125rem;
      line-height: 1.33;

      b {
        font-weight: bold;
      }
    }

    .related-product__link {
      display: block;
      color: inherit;

      &:hover {
        .item__overlay {
          opacity: 1;
        }
      }
    }

    .related-product__btn {
      background: #ee742e;
      padding: 0.125rem 1rem;
      color: white;
      text-transform: uppercase;
      font-size: 1.125rem;
      letter-spacing: 0.1px;
      font-weight: bold;
      min-height: 50px;
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
      outline: none;
      border: 0;
      width: 100%;
    }
  }

  .related-products__shop-link {
    color: #ee742e;
    padding: 0.75rem 2rem;
    width: 100%;
    min-height: 50px;
    background: white;
    border: 2px solid #ee742e;
    font-weight: bold;
    margin-left: auto;
    margin-right: auto;
    line-height: 1.4;
    transition: all 0.3s;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    text-transform: uppercase;
    margin-top: 1.5rem;
    margin-bottom: 3rem;

    &:hover {
      color: white;
      background: #ee742e;
    }
  }
}

@media (min-width: $mobile-width + 1) {
  .products-page {
    padding-bottom: 5.125rem;

    .products__filters-form {
      display: flex;
      justify-content: space-between;
      max-width: 81.75rem;
      margin-left: auto;
      margin-right: auto;
      margin-bottom: 3.8rem;

      .filter__item {
        margin-bottom: 0;
        align-items: flex-start;
        flex-basis: 320px;

        &:nth-child(2) {
          margin: 0 1rem;
        }
      }

      .filter__item-label {
        font-weight: bold;
        font-size: 1.125rem;
        margin-bottom: 1rem;
      }
    }
  }

  .products__banner {
    text-align: left;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    min-height: 669px;
    padding-top: 4rem;
    padding-left: 6rem;
    padding-bottom: 8.3125rem;

    .banner__title {
      font-size: 5rem;
    }

    .banner__text {
      margin: 0;
      font-size: 1.75rem;
    }
  }
}

@media (min-width: $screen-large-width) {
  .products__banner {
    padding-left: 10.4375rem;
  }

  .products-list {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    justify-items: center;
    row-gap: 3.75rem;
    column-gap: 1.5rem;
    padding-bottom: 3.3rem;
    margin: 0;
  }

  .products-list__item-wrap {
    margin: 0;

    .item__img-wrap .item__overlay .item__overlay-text {
      display: block;
    }

    .item__bottom {
      .item__story,
      .item__category {
        display: none;
      }

      .item__price {
        margin-top: 1.5rem;
      }
    }
  }

  .product-page__top {
    display: flex;
    justify-content: space-between;
    max-width: 1110px;
    margin: 0 auto;
    padding: 0 1rem;
    box-sizing: content-box;
  }

  .product-page {
    padding-top: 1.5rem;
    padding-bottom: 7.5rem;

    .product-slider-wrap {
      margin-bottom: 0;
      margin-left: 0;
      margin-right: 0;
      flex-grow: 1;

      .sliders-container {
        padding: 0 54px 0 40px;

        &.no-slider {
          padding-left: 0;
          padding-right: 67px;
        }
      }

      .splide__arrow--prev {
        left: -46px;
      }

      .splide__arrow--next {
        right: -46px;
      }
    }

    .product-info-wrap {
      padding-left: 1.9375rem;
      padding-right: 0;
      display: flex;
      justify-content: flex-start;
      max-width: 444px;
      box-sizing: content-box;
      flex-grow: 1;
    }

    .product-info {
      width: 100%;
    }

    .product__title {
      text-align: left;
      font-size: 3rem;
    }

    .product__counter-wrap {
      max-width: 350px;
      justify-content: space-between;
      position: relative;
      margin-left: 0;
      margin-right: 0;
      margin-bottom: 2.5rem;
    }

    .product__price {
      font-size: 2rem;
      padding-bottom: 0.4rem;
    }

    .product__action {
      margin-bottom: 2.1875rem;
    }

    .product__text {
      font-size: 1.125rem;
      margin: 0;
    }

    .product__actions {
      justify-content: flex-start;
    }

    .related-products {
      margin-top: 5.75rem;
      max-width: 1110px;
      margin-left: auto;
      margin-right: auto;
      box-sizing: content-box;
    }
  }

  .product__counter-actions {
    font-size: 1.5rem;
  }

  .product__counter-label {
    font-size: 1.125rem;
  }

  .products-page {
    .products__section-title {
      margin-bottom: 2.625rem;
    }
  }

  .home-page-products {
    margin-top: 0;
    margin-bottom: 6rem;
  }

  .story-page__related-products {
    order: -1;
    margin: 3.875rem 0 2rem;
    padding: 0 1rem;
    max-width: 255px;
    width: 100%;
    box-sizing: content-box;
  }
}
