.topbar {
  @include horizontally-centered;
  @include page-horizontal-padding;

  font-family: $font-family-antonio;
  text-transform: uppercase;
  max-width: $page-max-width;
  position: relative;
  z-index: 100;

  @include breakpoint-mobile {
    padding-left: 0;
    padding-right: 0;
    margin-bottom: 0;
  }
}

.topbar__main-text {
  color: #000;
  display: inline-block;
  font-family: $font-family-antonio;
  margin-left: 5px;
  font-size: 2rem;
}

.topbar__menu-item {
  position: relative;
  font-size: 1.3rem;

  &:hover {
    text-decoration: underline;
  }

  @include breakpoint-mobile {
    font-size: 0.75rem;
    padding: 0.5rem 0;
  }
}

.topbar__menu-items {
  display: flex;
  justify-content: space-evenly;
  margin-left: auto;
  list-style: none;
  font-size: 1rem;

  @include breakpoint-mobile {
    font-size: 0.5rem;
    padding: 7px 15px;
    width: 100%;
    padding-right: 33%;
    background: $color-light-gray;
    list-style-type: none;
    transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1);
  }
}

.topbar__menu {
  background-color: #ffffff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 2rem 0;

  @include breakpoint-mobile {
    display: block;
    padding: 1.5rem 0 0;
  }
}

.topbar__logo {
  display: flex;
  align-items: center;
  justify-content: flex-start;

  @include breakpoint-mobile {
    margin-bottom: 1.5rem;
    margin-left: 25px;

    img {
      max-height: 100px;
    }
  }

  @include breakpoint-screen-medium {
    img {
      max-width: 120px;
    }
  }
}

.topbar__support-button-content {
  display: flex;
  color: white;
}

.topbar__support-button {
  display: flex;
  justify-content: center;
  background-color: $color-main;
  padding: 15px 24px;
  color: white;
  width: $paybox-width;
  text-align: center;
  text-transform: uppercase;
  text-decoration: none;

  @include breakpoint-mobile {
    width: 100%;
    display: flex;
    font-size: 0.75rem;
    position: absolute;
    transform: translateY(-100%);
    z-index: 2;
    transition: transform 0.2s 0.2s ease-in-out;
  }
}

.topbar__support-button--active {
  transform: translateY(0%);
}

.topbar__basket {
  margin-left: 0.75rem;
  border: 0;
  background: none;
  padding: 0;
  align-items: center;
  display: none;
  position: relative;

  img {
    height: 22px;
  }

  .topbar__basket-counter {
    background: #ee742e;
    border-radius: 50%;
    position: absolute;
    top: 0;
    right: 0;
    width: 19px;
    height: 19px;
    color: white;
    font-size: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;
    transform: translateX(8px) translateY(-8px);
    opacity: 0;
    transition: opacity 0.3s;

    .fa-heart {
      top: 1px;
      position: relative;
    }
  }
}

.topbar__menu-right {
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  width: 33%;
  display: flex;
  align-items: center;

  .topbar__basket {
    display: block;
  }
}

.topbar__language-switch {
  display: flex;
  align-items: center;

  button {
    font-family: $font-family-bebas-neue;
    text-transform: uppercase;
    font-size: 1.25rem;
    font-weight: 700;
    border: 1px solid transparent;
    background-color: transparent;
    padding: 0 5px;
    position: relative;
    color: black;

    @include breakpoint-mobile {
      font-size: 0.75rem;
    }

    &.selected {
      color: $color-main;
    }
  }

  form,
  form button {
    display: flex;
    align-items: center;
  }
}

.topbar__language-switch--desktop {
  display: none;
}

.topbar__menu-link {
  text-decoration: none;
  color: #232323;
  transition: color 0.3s ease;
  white-space: nowrap;
  position: relative;
  cursor: pointer;

  &:hover {
    color: $color-main;
    text-decoration: underline;
  }
}

.topbar__menu-toggle {
  width: 40%;
  display: block;
  user-select: none;
  margin: 0 32px;

  @include breakpoint-mobile {
    width: 100%;
    display: flex;
    position: relative;
    margin: 3px 0 0 0;
  }
}

.topbar__support-heart {
  margin-left: 3px;
}

.topbar__mobile-checkbox {
  display: none;

  @include breakpoint-mobile {
    display: initial;
    margin-right: 30px;
    width: 30px;
    height: 30px;
    cursor: pointer;
  }
}

.topbar__hamburger {
  @include display-mobile-only;
  border: none;
  cursor: pointer;
  background-color: transparent;
  outline: none;

  &:focus {
    outline: 0;
  }

  &:active {
    background-color: none;
  }
}

.topbar__hamburger-box {
  width: 30px;
  height: 30px;
  margin-right: 25px;
  display: inline-block;
  position: relative;
}

@mixin hamburger-line {
  width: 100%;
  height: 4px;
  background-color: black;
  position: absolute;
}

.topbar__hamburger-inner {
  @include hamburger-line;

  left: 0;
  top: 50%;
  transform: translateY(-50%);
  transition: background-color 0.1s 0.2s ease-in-out;

  &::before {
    @include hamburger-line;

    top: -10px;
    width: 130%;
    content: "";
    right: 0;
    transition: transform 0.2s 0.2s ease-in-out;
  }

  &::after {
    @include hamburger-line;

    top: 10px;
    width: 65%;
    content: "";
    right: 0;
    transition: transform 0.2s 0.2s ease-in-out;
  }
}

.is-topbar__hamburger--active {
  .topbar__hamburger-inner {
    background-color: transparent;
  }

  .topbar__hamburger-inner::before {
    transform: translateY(10px) rotate(-45deg);
  }

  .topbar__hamburger-inner::after {
    transform: translate(-10px, -10px) rotate(45deg) scaleX(2);
  }
}

.topbar__logo-block {
  @include breakpoint-mobile {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}

.topbar__menu-link--dropdown {
  cursor: pointer;
}

.topbar__menu-link--dropdown-content {
  display: none;
}

.topbar__dropdown {
  padding: 0.25rem 0.125rem;

  .topbar__menu-item {
    text-align: center;
    border-bottom: 1px solid $color-black;
    padding: 0.25rem 0;
    margin: 0;

    &:first-child {
      margin-top: 0;
      padding-top: 0;
    }

    &:last-child {
      margin-bottom: 0;
      padding-bottom: 0;
      border: 0;
    }
  }
}

@media (min-width: ($mobile-width + 1)) {
  .topbar__menu-right {
    display: none;
  }

  .topbar__language-switch--desktop {
    display: flex;
    position: relative;
    top: 0;
    right: 0;
    transform: none;
    white-space: nowrap;
  }

  .topbar__basket {
    display: flex;
    position: relative;
    top: -4px;

    img {
      height: auto;
    }
  }

  .topbar__support-button {
    margin: 0 0.5rem;
  }

  .topbar__dropdown {
    padding: 0.5rem 0.25rem;

    .topbar__menu-item {
      padding: 0.5rem 0;
      margin: 0.15rem 0;
    }
  }
}
