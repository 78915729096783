.order-payment-form {
  .payment__title {
    text-align: center;
  }

  .payment__info {
    font-size: 1.1rem;
    line-height: 1.07;
    font-weight: bold;
    margin-top: 2rem;
    margin-bottom: 1.5rem;

    span {
      margin-left: 0.5rem;
      color: #ee742e;
      font-size: 1.3rem;
      line-height: 0.64;
    }
  }

  .payment__products-headers {
    display: none;
  }

  .payment__products-list {
    .product__name-col {
      font-size: 1.5rem;
      font-family: $font-family-bebas-neue;
      margin-bottom: 1rem;

      span {
        font-family: $font-family-roboto;
        color: #ee742e;
        margin-left: 0.5rem;
      }
    }

    .product__price-col {
      font-weight: bold;
      line-height: 1.1;
      font-size: 1.25rem;
      white-space: nowrap;
    }
  }

  .payment__products-list-item {
    padding: 1.25rem 0;
    border-bottom: 2px solid #e5e5e5;

    &:first-child {
      border-top: 2px solid #e5e5e5;
    }
  }
}

@media (min-width: $mobile-narrow-width + 1) {
  .order-payment-form {
    .payment__info {
      font-size: 1.5rem;
      margin-top: 3.75rem;
      margin-bottom: 2.75rem;

      span {
        font-size: 2.5rem;
      }
    }

    .payment__products-headers {
      display: flex;
      justify-content: space-between;
      padding-bottom: 1rem;
      font-weight: bold;
      font-size: 1rem;
      border-bottom: 2px solid #e5e5e5;
    }

    .payment__products-list {
      border-bottom: 2px solid #e5e5e5;
      padding: 1rem 0;
    }

    .payment__products-list-item {
      border: 0;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 1rem 0;

      &:first-child {
        border: 0;
      }

      .product__name-col {
        padding-right: 2rem;
        margin: 0;
        font-size: 1.3rem;

        span {
          margin-left: 1rem;
        }
      }
    }
  }
}
