.block-aboutUs {
  margin-top: -60px;
}

.about-us {
  max-width: $page-max-width;
  display: flex;
  height: 400px;
  margin: 80px auto;
  @include breakpoint-mobile {
    display: block;
    height: auto;
  }
}

.about-us__image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.about-us__left-side {
  width: 50%;
  @include breakpoint-mobile {
    width: 100%;
  }
}

.about-us__right-side {
  width: 50%;
  padding-left: 30px;
  @include breakpoint-mobile {
    width: calc(100% - 10px);
    padding-left: 10px;
    margin-top: 2rem;
  }
}

.about-us__title {
  font-family: $font-family-bebas-neue;
  font-size: 7rem;

  @include breakpoint-screen-small {
    font-size: 4rem;
  }

  @include breakpoint-mobile {
    font-size: 3rem;
  }
}

.about-us__lead {
  font-size: 1.1rem;
  line-height: 1.5;
  font-family: $font-family-roboto;
  width: 65%;
  margin-top: 2rem;
  text-align: justify;
}

.about-us__text-highlight {
  color: $color-main;
  border-bottom: 2px solid $color-main;
  text-decoration: none;
}

.about-us__buttons {
  margin-top: 20px;
  font-family: $font-family-roboto;
  font-weight: bold;
}

.about-us__button-1 {
  display: inline-block;
  padding: 8px 20px;
  text-align: center;
  border: 3px solid black;
  width: 200px;

  &:hover {
    background-color: black;
    color: white;
  }
}

.about-us__button-2 {
  text-align: center;
  width: 200px;
  display: inline-block;
  padding: 8px 30px;
  background-color: $color-main;
  border: 3px solid $color-main;
  color: white;
  margin-left: 15px;

  &:hover {
    background-color: white;
    border: 3px solid $color-main;
    color: $color-main;
  }
}

.about-us__texts {
  margin-left: 80px;
}
