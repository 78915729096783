$richtext-mobile-margin: 15px;

.about-us-page__wrapper {
  max-width: $page-max-width;
  margin: 0px auto;
}

.about-us-page__slide {
  max-width: $page-max-width;
  width: 100%;
  min-height: 600px;
  height: 300px;
  margin: 0 auto;
  position: relative;
  background-size: cover;
  background-position: center;
  padding-top: 100%;
  overflow: visible;

  @include breakpoint-mobile {
    min-height: unset;
  }

  @include breakpoint-tablet {
    display: flex;
    align-items: center;
    padding: 3rem 0;
    width: 100%;
  }
}

.about-us-page__image {
  height: 300px;
  width: 100%;
  background-size: cover;
  background-position: center;

  @include breakpoint-tablet {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
  }
}

.about-us-page__icon-wrapper {
  cursor: pointer;
  background-color: transparent;
}

.about-us-page__icon-wrapper-next {
  margin-left: 1rem;
}

.about-us-page__icon-next {
  transform: translateY(-4px);
}

.about-us-page__icon-prev {
  transform: translate(-3px, -4px);
}

.about-us-page__info-wrapper {
  margin-bottom: 1.3rem;
}

.about-us-page__attribue {
  font-weight: bold;
}

.about-us-page__attr-value {
  font-weight: 300;
}

.about-us-page__title {
  display: inline-block;
  font-size: 5rem;
  line-height: 1;
  text-transform: uppercase;
  margin-bottom: 2rem;
  font-family: BebasNeue, sans-serif;
  max-width: 425px;

  @include breakpoint-mobile {
    font-size: 2.5rem;
    padding-top: 20px;
    padding-left: 24px;
  }

  @include breakpoint-tablet {
    color: $color-light;
  }

  @include breakpoint-screen-medium {
    font-size: 4rem;
    margin-left: 2rem;
  }

  @include breakpoint-screen-large() {
    padding: 0 0 0 3.75rem;
  }
}

.about-us-page__title-mobile {
  display: none;
  @include breakpoint-mobile {
    display: block;
  }
}

.about-us-page__title-desktop {
  @include breakpoint-mobile {
    display: none;
  }
}

.about-us-page__main-info {
  font-size: 2rem;
  display: none;
}

.about-us-page__button-wrapper {
  @include breakpoint-mobile {
    display: inline;
    margin: 5px;
  }
}

.about-us-page__richtext {
  font-family: $font-family-roboto;
  line-height: 1.4;
  padding: 0 $richtext-mobile-margin;
  margin: 0 auto;
  max-width: 640px;

  img {
    max-width: 100%;
    height: auto;
    object-fit: contain;
    margin: 30px auto;
    display: block;
  }

  h2 {
    text-align: center;
    font-family: $font-family-bebas-neue;
    font-size: 4rem;
    margin: 80px 0px;
    text-transform: uppercase;

    @include breakpoint-mobile {
      margin: 40px 0px 10px 0px;
      font-size: 3rem;
    }
  }

  h3 {
    color: $color-main;
    text-align: center;
    font-size: 6rem;
    padding-left: 10%;
    margin: 50px 0px;
    font-style: italic;
    @include breakpoint-mobile {
      font-size: 2rem;
    }
  }

  h4 {
    font-size: 2rem;
    font-family: $font-family-antonio;
    margin-bottom: 0.8rem;
    @include breakpoint-mobile {
      font-size: 1.5rem;
    }
  }

  b {
    font-weight: bold;
  }
}

.about-us-page__contact-box {
  display: flex;
  justify-content: space-around;
  align-items: center;
  max-width: $page-max-width;
  margin: 90px auto 80px auto;
  padding: 40px 0px 60px 60px;
  box-shadow: $box-shadow;
  width: 98%;

  @include breakpoint-mobile {
    flex-direction: column;
    margin: 40px auto 50px auto;
    position: static;
    width: calc(100% - 30px);
    padding: 20px 15px 30px 15px;
  }
}

.about-us-page__contact-richtext {
  font-family: $font-family-roboto;
  line-height: 1.4;

  @include breakpoint-mobile {
    width: calc(100% - (#{$richtext-mobile-margin} * 2));
    margin: 0px $richtext-mobile-margin;
  }

  img {
    max-width: 100%;
    height: auto;
    object-fit: contain;
    margin: 30px auto;
  }

  h2 {
    text-align: center;
    font-family: $font-family-bebas-neue;
    font-size: 4rem;
    margin: 80px 0px;
    text-transform: uppercase;

    @include breakpoint-mobile {
      margin: 40px 0px 10px 0px;
    }
  }

  h3 {
    color: $color-main;
    text-align: center;
    font-size: 6rem;
    padding-left: 10%;
    margin: 50px 0px;
    font-style: italic;
  }

  h4 {
    font-size: 2rem;
    font-family: $font-family-antonio;
    margin-bottom: 0.8rem;
  }

  b {
    font-weight: bold;
  }
}

.about-us-page__contact-image {
  height: 500px;
  width: 60%;
  flex: 1;
  object-fit: cover;

  @include breakpoint-mobile {
    position: static;
    margin-top: 40px;
    width: 100%;
    height: auto;
  }
}

@media (min-width: $screen-large-width) {
  .about-us-page__richtext {
    max-width: 1440px;
    padding: 0 calc(383px + 1rem) 0 240px;
  }
}
