.footer {
  border-top: 3px solid grey;
  margin-top: 50px;
  font-family: $font-family-antonio;
  padding: 50px 0px;
  line-height: 1.3;
  font-size: 0.8rem;
  position: relative;
  z-index: 100;

  @include print {
    display: none;
  }
}

.footer__items {
  padding: 0px 30px;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  max-width: $page-max-width;
  margin-right: auto;
  margin-left: auto;

  @include breakpoint-mobile-narrow {
    flex-wrap: wrap;
    align-items: flex-start;
  }
}

.footer__items-texts {
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  text-align: left;

  @include breakpoint-mobile {
    flex-direction: column;
    width: 100%;
  }
}

.footer__item {
  font-size: 0.8rem;
  flex-grow: 1;
  font-family: $font-family-roboto;
  width: 20%;
  line-height: 1.3;

  &:not(:first-child) {
    margin-left: 20px;
  }

  @include breakpoint-mobile {
    width: 100%;
    text-align: center;
    margin-bottom: 2rem;

    &:not(:first-child) {
      margin-left: 0px;
    }
  }
}

.footer__rich-text {
  b {
    font-weight: bold;
  }

  i {
    font-style: italic;
  }

  p:not(:last-child) {
    margin-bottom: 1rem;
  }

  a {
    color: black;
    text-decoration: underline;
  }
}

.footer__item-social-media {
  flex-grow: 0.7;
  height: 100%;
  margin-top: auto;
  transform: translateY(-50%);

  @include breakpoint-mobile {
    margin: 15px auto;
  }
}

.footer__item-social-title {
  font-family: $font-family-roboto;
  font-weight: bolder;

  @include breakpoint-mobile {
    text-align: center;
  }
}

.footer__item-social-media-icons {
  display: flex;
}

.footer__item-social-media-icon {
  color: black;
  transform: scale(1.5);
  margin: 10px 15px 0px 0px;

  @include breakpoint-mobile {
    margin: 15px 20px;
  }
}

.footer__item-text {
  font-size: 1rem;
  line-height: 1.3;
}

.footer__item-left {
  text-align: left;

  @include breakpoint-mobile-narrow {
    text-align: right;
  }

  @include breakpoint-mobile {
    text-align: center;
  }
}

.footer__item-center {
  text-align: center;

  @include breakpoint-mobile-narrow {
    order: 1;
  }
}

.footer__item-right {
  text-align: right;
}

.footer__main-logo {
  width: 115px;
  margin-bottom: 16px;
}

.footer__logo {
  display: flex;
  align-items: center;
  justify-content: center;
}

.footer__main-text {
  color: #000;
  display: inline-block;
  font-family: $font-family-antonio;
  margin-left: 5px;
  font-size: 0.8rem;
}

.footer__circle {
  background-color: $color-main;
  height: $main-logo-font-size;
  width: $main-logo-font-size;
  display: inline-block;
  border-radius: calc(#{$main-logo-font-size} / 2);
}

.footer__link {
  color: #000;
  font-weight: bold;
  padding-bottom: 4px;
  border-bottom: 2px $color-main solid;
}

.logo-mock {
  display: flex;
  color: white;
  background-color: black;
  width: 140px;
  height: 50px;
}

.logo-mock__text {
  margin: auto;
  font-family: $font-family-antonio;
}

.footer__ee-logo {
  width: 150px;
}

.footer__item-wrapper {
  margin-bottom: 1rem;
}

.footer__policy-text {
  color: black;
  text-decoration: underline;
}
