@mixin circle($diameter) {
  width: $diameter;
  height: $diameter;
  border-radius: 50%;
}

@mixin mobile-horizontal-margin {
  @media (max-width: #{$mobile-width}) {
    margin-left: 15px;
    margin-right: 15px;
  }
}

@mixin page-horizontal-padding {
  padding-left: 15px;
  padding-right: 15px;
}

@mixin horizontally-centered {
  margin-left: auto;
  margin-right: auto;
}

@mixin display-mobile-only {
  @media (min-width: calc(#{$mobile-width} + 1px)) {
    display: none;
  }
}

@mixin page-horizontal-padding {
  padding-left: 15px;
  padding-right: 15px;
}

@mixin mobile-horizontal-margin {
  @media (max-width: #{$mobile-width}) {
    margin-left: 15px;
    margin-right: 15px;
  }
}
