$richtext-mobile-margin: 15px;

.story__wrapper {
  max-width: $page-max-width;
  margin: 0px auto;
}

.story__slide {
  max-width: $page-max-width;
  width: 100%;
  min-height: 600px;
  height: auto;
  margin: 0 auto;
  position: relative;

  @include breakpoint-mobile {
    min-height: unset;
  }

  @include breakpoint-tablet {
    display: flex;
    align-items: center;
    padding: 3rem 0;
  }

  @include print {
    display: none;
  }
}

.story__image {
  height: 300px;
  width: 100%;
  background-size: cover;
  background-position: center;

  @include breakpoint-tablet {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
  }
}

.story__icon-wrapper {
  cursor: pointer;
  background-color: transparent;
}

.story__icon-wrapper-next {
  margin-left: 1rem;
}

.story__icon-next {
  transform: translateY(-4px);
}

.story__icon-prev {
  transform: translate(-3px, -4px);
}

.story__texts-wrapper {
  padding: 2rem;
  position: relative;
  z-index: 10;
  max-width: 385px;

  @include breakpoint-screen-large() {
    padding: 0 0 0 3.75rem;
  }

  @include breakpoint-mobile {
    padding: 1rem 2rem;
  }
}

.story__texts {
  line-height: 1.25;

  @include breakpoint-tablet {
    color: $color-light;
  }
}

.story__info-wrapper {
  margin-bottom: 1.3rem;
}

.story__attribue {
  font-weight: bold;
}

.story__attr-value {
  font-weight: 300;
}

.story__title {
  display: inline-block;
  font-size: 3.5rem;
  line-height: 1;
  text-transform: uppercase;
  margin-bottom: 2rem;
  font-family: BebasNeue, sans-serif;

  @include breakpoint-mobile {
    font-size: 2rem;
  }
  @include breakpoint-screen-medium {
    font-size: 3rem;
  }
}

.story__main-info {
  font-size: 2rem;
  display: none;
}

.story__button-wrapper {
  @include breakpoint-mobile {
    display: inline;
    margin: 5px;
  }
}

.story__richtext {
  font-family: $font-family-roboto;
  line-height: 1.4;
  padding: 1.5rem $richtext-mobile-margin 0;
  margin: 0 auto;
  max-width: 640px;

  img {
    max-width: 100%;
    height: auto;
    object-fit: contain;
    margin: 30px auto;
    display: block;

    &.full-width {
      width: 100%;
    }
  }

  h2 {
    text-align: center;
    font-family: $font-family-bebas-neue;
    font-size: 4rem;
    margin: 80px 0px;
    text-transform: uppercase;

    @include breakpoint-mobile {
      margin: 40px 0px 10px 0px;
      font-size: 3rem;
    }
  }

  h3 {
    color: $color-main;
    font-size: 2.5em;
    border-left: 4px solid $color-main;
    padding-left: 10%;
    margin: 50px 0px;
    font-style: italic;
    @include breakpoint-mobile {
      font-size: 2rem;
    }
  }

  h4 {
    @include breakpoint-mobile {
      font-size: 1.5rem;
    }
  }

  b {
    font-weight: bold;
  }

  p {
    margin: 16px 0px;
  }
}

@media (min-width: $screen-large-width) {
  .story-page__content {
    display: flex;
    max-width: 1400px;
    margin: 0 auto;
    padding-right: calc(347px + 2rem);
  }

  .story__richtext {
    max-width: none;
    padding: 1.5rem 0 0;
    margin: 0;
    flex-grow: 1;
  }

  .story__richtext-content {
    max-width: 504px;
    margin: 0 auto;
  }
}
