.our-achievements {
  margin-bottom: 130px;

  &__main-title {
    text-align: center;
    margin-bottom: 50px;
    font-weight: 700;
  }
}

.help {
  margin: 2rem 0 0 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: 150px;

  &__quotations {
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 0 30px;
    position: relative;
    background-image: url(../images/line_heart.png);
    background-position: bottom 80px right;
    background-repeat: no-repeat;

    @include breakpoint-screen-xlarge {
      padding: 0;
    }

    @include breakpoint-screen-small {
      background-image: none;
      width: 100%;
      margin-bottom: 3rem;
    }

    h3,
    &--quote {
      color: $color-main;
      text-transform: uppercase;
      font-family: $font-family-bebas-neue;
      font-size: 2.5rem;
      margin-bottom: 0.5rem;
    }
    p {
      font-size: 1.25rem;
      line-height: 1.2;
      width: 60%;
      @include breakpoint-screen-small {
        width: 100%;
      }
    }
    &--quote {
      font-weight: 700;
      max-width: 20rem;
      @include breakpoint-screen-small {
        margin: 3rem auto;
        text-align: center;
      }
    }
  }

  &__blocks {
    width: 50%;
    display: flex;
    flex-direction: column;

    @include breakpoint-screen-small {
      width: 100%;
    }
  }
  &__block {
    display: flex;
    &--reversed {
      flex-direction: row-reverse;
    }
    &--image {
      width: 50%;
      background-size: cover;
      background-position: center center;
      @include breakpoint-mobile-narrow {
        width: 100%;
        min-height: 225px;
      }
    }
    &--content {
      width: 50%;
      background-color: $color-light-gray;
      padding: 30px 40px;
      @include breakpoint-mobile-narrow {
        width: 100%;
      }
    }
    p {
      color: $color-grey-deep;
      line-height: 1.2;
    }
    h3 {
      color: $color-main;
      text-transform: uppercase;
      font-family: $font-family-bebas-neue;
      font-size: 5rem;
      font-weight: 700;
    }

    @include breakpoint-mobile-narrow {
      flex-direction: column;
    }
  }
}

.statistics {
  display: flex;
  justify-content: space-between;
  margin-bottom: 150px;

  @include breakpoint-mobile {
    flex-direction: column;
  }

  &__item {
    width: 32%;
    text-align: center;
    @include breakpoint-mobile {
      width: 100%;
      margin-top: 2rem;
      padding: 0 15px;
    }

    &--title {
      color: black;
      text-transform: uppercase;
      font-family: $font-family-bebas-neue;
      margin-bottom: 1.5rem;
      font-size: 2.5rem;
    }
    &--icon {
      max-height: 100px;
    }
    &--value {
      color: $color-main;
      text-transform: uppercase;
      font-family: $font-family-bebas-neue;
      margin-top: 1.5rem;
      font-size: 5rem;
      font-weight: 700;
    }
  }
}
