.slider {
  position: relative;
  margin: 0 4rem 0;

  @include breakpoint-mobile {
    margin: 0 2rem 0;
  }

  &__siema {
    position: relative;
    overflow: hidden;
  }

  &__icon-wrapper-prev,
  &__icon-wrapper-next {
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    color: $color-medium-gray;
    border: 4px solid $color-medium-gray;
    border-radius: 50%;
    width: 2.5rem;
    height: 2.5rem;
    font-size: 1rem;
    z-index: 10;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);

    @include breakpoint-screen-xlarge {
      border: 7px solid $color-medium-gray;
      width: 100px;
      height: 100px;
      font-size: 40px;
    }
  }

  &__icon-wrapper-prev {
    left: -25px;
    @include breakpoint-screen-xlarge {
      left: -100px;
    }
  }

  &__icon-wrapper-next {
    right: -25px;
    @include breakpoint-screen-xlarge {
      right: -100px;
    }
  }

  &__image {
    height: 350px;
    background-position: center center;
    background-size: cover;
  }

  &__content {
    background-color: $color-light-gray;
    padding: 45px 15px;
  }

  &__title {
    font-family: $font-family-bebas-neue;
    font-size: 2.5rem;
    margin-bottom: 1.5rem;
    text-transform: uppercase;
    display: block;
    color: inherit;
  }

  &__description {
    display: block;
    color: inherit;
  }

  &__slide {
    margin: 0 25px;
    position: relative;

    @include breakpoint-screen-large {
      margin: 0 50px;
    }

    &:hover {
      .slider__content {
        background-color: $color-main;
        color: white;
      }
    }
  }
}
