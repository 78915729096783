.newsletter {
  width: 100%;
  background-color: $color-main;
  color: white;
}

.newsletter__content {
  width: 100%;
  max-width: $page-max-width;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  padding: 50px;

  @include breakpoint-mobile {
    flex-direction: column;
  }
}

.newsletter__texts {
  display: flex;
  flex-direction: column;
  font-family: $font-family-roboto;
  font-size: 20px;
  margin-bottom: 1rem;
  width: 100%;
  margin-right: 2rem;
}

.newsletter__title {
  font-family: $font-family-bebas-neue;
  font-size: 70px;

  @include breakpoint-mobile {
    font-size: 2rem;
  }
}

.newsletter__text {
  width: 70%;
  font-weight: 300;

  @include breakpoint-screen-large {
    width: 100%;
  }

  @include breakpoint-mobile {
    width: 100%;
    font-size: 1rem;
    margin-top: 1rem;
  }
}

.newsletter__form {
  display: block;
  width: 100%;
  margin-top: 1rem;
}
.newsletter__input {
  display: block;
  color: white;
  box-sizing: border-box;
  border-bottom: 2px solid;
  border-top: 0px;
  border-left: 0px;
  border-right: 0px;
  line-height: 1.5;
  text-transform: uppercase;
  width: 100%;
  height: 40px;
  font-family: $font-family-bebas-neue;
  font-size: 2rem;
  background-color: $color-main;
  &::placeholder {
    color: white;
  }

  @include breakpoint-mobile {
    font-size: 1rem;
  }
}
.newsletter__button {
  display: block;
  margin-left: auto;
  transition: 200ms;
  background-color: white;
  border: 2px solid white;
  color: $color-main;
  font-size: 1rem;
  padding: 15px 35px;
  text-transform: uppercase;
  font-family: $font-family-roboto;
  font-weight: 600;
  letter-spacing: 2px;
  margin-top: 2rem;
  &:hover {
    cursor: pointer;
    color: white;
    background-color: $color-main;
  }
}

.newsletter__feedback {
  font-family: $font-family-bebas-neue;
  font-size: 1.5rem;
  margin-top: 1rem;
}

.is-newsletter__feedback--error {
  color: white;
}

.is-newsletter__feedback--success {
  color: white;
}

.newsletter__button-text {
  display: inline-block;
  font-weight: 600;
}

.newsletter__spinner-wrapper {
  display: none;
  position: absolute;
  transform: translateX(-125%);
}

.newsletter__checkbox-custom {
  position: absolute;
  top: 0px;
  left: 0px;
  height: 18px;
  width: 18px;
  background-color: transparent;
  transition: all 0.3s ease-out;
  border: 2px solid #000000;
}

.newsletter__checkbox-label {
  position: relative;
  cursor: pointer;
  clear: both;
  padding-left: 20px;
  height: 18px;
}

.newsletter__checkbox {
  position: absolute;
  opacity: 0;
  transform: translateX(-24px);
  cursor: pointer;
}

.newsletter__checkbox-custom--orange {
  @extend .newsletter__checkbox-custom;

  border: 2px solid white;
}

.newsletter__checkbox-label input:checked ~ .newsletter__checkbox-custom {
  background-color: white;
  transform: rotate(0deg) scale(1);
  opacity: 1;
  border: 2px solid white;
}
.newsletter__agreement {
  margin-top: 1.5rem;
  display: flex;
}

.textblink {
  animation: textblink 400ms 1;
}

@keyframes textblink {
  0% {
    opacity: 1;
  }

  50% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.newsletter__agreement-text {
  font-family: $font-family-roboto;
  font-size: 16px;
  padding-left: 2rem;
  opacity: 0.6;
  font-weight: 300;

  @include breakpoint-mobile {
    font-size: 0.5rem;
  }
}
