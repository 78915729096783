.news-tile {
  width: 30%;
  display: block;
  height: 400px;
  margin: 20px auto 0px auto;

  @include breakpoint-mobile {
    width: 100%;
    background-repeat: no-repeat;
    background-image: linear-gradient($color-main, $color-main);
    background-size: 100% 290px;
  }
}

.news-tile__figure {
  height: 250px;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  margin: 0 auto;
  @include breakpoint-mobile {
    width: 90%;
    margin-top: 20px;
  }
}

.news-tile__title {
  font-family: $font-family-bebas-neue;
  font-weight: 400;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  background-color: rgba(0, 0, 0, 0.5);
  width: 100%;
  padding: 7px;
  font-size: 2rem;
}

.news-tile__lead {
  text-align: center;
  color: black;
  display: block;
  margin: 20px 0rem;
  font-family: $font-family-roboto;
}

.news-tile__date {
  text-align: center;
  color: black;
  display: block;
  font-style: italic;
  margin-top: 40px;
}
