.payment-return__content {
  padding-bottom: 2rem;
  margin-top: -130px;
}

.return__message {
  text-align: left;
  font-family: $font-family-bebas-neue;
  font-size: 4rem;
}

.return__button {
  box-sizing: border-box;
  font-family: $font-family-bebas-neue;
  width: 700px;
  padding: 10px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 40px;
  margin-bottom: 40px;
  background-color: white;
  border: 3px solid $color-main;
  color: $color-main;
  text-align: center;
  font-size: 1.5rem;
  display: block;

  @include breakpoint-mobile {
    width: 80%;
  }
}
