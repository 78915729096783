.news-index-page__title {
  text-transform: uppercase;
  font-family: $font-family-roboto;
}
// .news-index-page {

// }

.news-index-page__index {
  display: flex;
  justify-content: left;
  flex-wrap: wrap;
}
