.other-activities {
  margin-bottom: 150px;

  &__main-title {
    text-align: center;
    margin-bottom: 50px;
    font-weight: 700;
  }
  &__wrapper {
    margin: 2rem 0 0 0;
    display: flex;
    flex-direction: row-reverse;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: stretch;
    padding: 0 2rem;

    @include breakpoint-screen-large {
      padding: 0;
      margin: 4rem 0 3rem;
    }
    @include breakpoint-screen-small {
      flex-direction: column;
    }
  }

  &__items-small {
    width: 38%;
    @include breakpoint-screen-small {
      width: 100%;
      margin-top: 3rem;
    }
  }

  &__item-big {
    width: 58%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    @include breakpoint-screen-small {
      width: 100%;
    }

    &--image {
      background-size: cover;
      background-repeat: no-repeat;
      height: 395px;
      width: 100%;
    }
    &--title {
      text-transform: uppercase;
      font-family: $font-family-bebas-neue;
      margin: 1rem 0 0.75rem;
      font-size: 2.5rem;
      font-weight: 700;
      a {
        color: $color-main;
      }
    }
    &--description {
      font-size: 1.15rem;
      line-height: 1.2;
      font-weight: 700;
      margin-bottom: 0;
      a {
        color: black;
      }
    }
  }

  &__item-small {
    margin-bottom: 2rem;
    display: flex;
    flex-direction: row;

    &:last-child {
      margin-bottom: 0;
    }

    &--image {
      width: 170px;
      height: 170px;
      background-size: cover;
      background-position: center center;
    }
    &--content {
      padding-left: 20px;
    }
    &--title {
      text-transform: uppercase;
      font-family: $font-family-bebas-neue;
      margin: 0 0 0.5rem;
      font-size: 1.75rem;
      font-weight: 700;
      a {
        color: $color-main;
      }
    }
    &--description {
      font-size: 1.15rem;
      line-height: 1.2;
      a {
        color: black;
      }
    }
  }

  &__show-more {
    color: $color-main;
    border: 2px solid $color-main;
    padding: 1rem;
    text-transform: uppercase;
    font-family: $font-family-roboto;
    font-weight: 700;
    text-align: center;
    margin: 0 auto;
    display: inline-block;
    justify-content: center;

    &:hover {
      color: white;
      background-color: $color-main;
    }
  }
  &__show-more-wrapper {
    text-align: center;
    margin-top: 30px;
  }
}
