.carousel {
  position: relative;
  overflow: hidden;
  margin: 0 0 4rem;

  @include breakpoint-tablet {
    min-height: 550px;
  }
  @include breakpoint-screen-large() {
    min-height: 685px;
    margin: 0 0 80px;
  }

  @include breakpoint-mobile {
    margin: 0 0 2rem;
  }
}

.carousel__siema {
  position: relative;
}
.carousel__controls {
  position: absolute;
  top: calc(150px - 12px);
  left: 0;
  z-index: 10;
  display: flex;
  width: 100%;
  justify-content: space-between;
  padding: 0 1rem;

  @include breakpoint-tablet {
    width: auto;
    top: 2.6rem;
    left: 100px;
    padding: 0;
    justify-content: flex-start;
  }

  @include breakpoint-screen-large() {
    top: 3rem;
  }
  @media (min-width: 1500px) {
    left: 5vw;
  }

  .carousel__next {
    margin: 0 0 0 1rem;
  }

  .carousel__prev,
  .carousel__next {
    width: 24px;
    height: 24px;
    font-size: 12px;

    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    border: 2px solid $color-light;
    border-radius: 40px;
    white-space: nowrap;
    color: $color-light;

    @include breakpoint-tablet {
      width: 2.5rem;
      height: 2.5rem;
      font-size: 16px;
    }
  }
}

.carousel__slide {
  margin: 0 auto;
  position: relative;

  @include breakpoint-tablet {
    min-height: 550px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 6rem 100px 3rem;
  }

  @include breakpoint-screen-large() {
    min-height: 685px;
  }

  @media (min-width: 1500px) {
    padding: 6rem 5vw 3rem;
  }
}

.carousel__content {
  position: relative;
  z-index: 10;
  width: 100%;
  padding: 2rem 2rem;

  @include breakpoint-tablet {
    max-width: 290px;
    color: $color-light;
    padding: 2rem 0;
  }

  @include breakpoint-screen-large() {
    max-width: 290px;
  }
}

.carousel__link-wrapper {
  position: relative;
}
.carousel__link {
  position: absolute;
  z-index: 10;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
}

.carousel__actions {
  margin: 1.5rem 0 0 0;

  .btn {
    margin: 0 1rem 1rem 0;
    width: 100%;
    padding-left: 0;
    padding-right: 0;

    @include breakpoint-tablet {
      width: 280px;
    }

    &:last-child {
      margin: 0;
    }
  }
}

.carousel__icon-prev {
  transform: translate(-1px, 1px);
  @include breakpoint-tablet {
    transform: translate(-2px, 1px);
  }
}
.carousel__icon-next {
  transform: translate(1px, 1px);
  @include breakpoint-tablet {
    transform: translate(1px, 1px);
  }
}

.carousel__bg {
  width: 100%;
  height: 300px;
  background-size: cover;
  background-position: center;
  display: block;

  @include breakpoint-tablet {
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    z-index: 5;
  }
}

.carousel__title {
  display: inline-block;
  font-size: 5rem;
  line-height: 1;
  text-transform: uppercase;
  margin-bottom: 1.5rem;
  font-family: $font-secondary;

  @include breakpoint-mobile {
    font-size: 2.5rem;
  }

  @include breakpoint-screen-medium {
    font-size: 3rem;
  }
}

.carousel__button {
  margin-top: 1.5rem;
  padding: 0.9rem;
}

.carousel__subtitle {
  padding: 0.1rem 5rem 0.1rem 3rem;
  font-size: 5rem;
  background-color: #f2f2f2;
  display: inline-block;
  margin-bottom: 1.5rem;
}
