.header-image-payment__main {
  margin: 0 auto;
  width: 100%;
  max-width: $page-max-width;
  height: 600px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;

  @include breakpoint-mobile {
    width: 100%;
    height: 75vw;
  }
}

.header-image-payment__texts-step-wrapper {
  width: 50%;
  height: 100%;
  position: relative;

  @include breakpoint-mobile {
    width: 100%;
  }
}

.header-image-payment__texts-step {
  position: absolute;
  left: 15%;
  top: 25%;
  font-family: $font-family-bebas-neue;

  @include breakpoint-mobile {
    top: 20px;
  }
}

.header-image-payment__texts-step-desc {
  font-size: 10rem;
  margin-bottom: 2rem;

  @include breakpoint-mobile {
    font-size: 3rem;
  }
}
.header-image-payment__texts-step-number {
  font-size: 5rem;

  @include breakpoint-mobile {
    font-size: 2rem;
  }
}

.header-image-payment__texts-description-wrapper {
  width: 50%;
  height: 100%;
  position: relative;

  @include breakpoint-mobile {
    display: none;
  }
}

.header-image-payment__texts-description {
  position: absolute;
  width: 50%;
  left: 15%;
  top: 25%;
}

.header-image-payment__texts-description-title {
  margin-bottom: 2rem;
  font-family: $font-family-bebas-neue;
  font-size: 2.25rem;
  line-height: 1.15;
}

.header-image-payment__texts-description-lead {
  font-family: $font-family-roboto;
  line-height: 1.4;
}

.header-image-payment__texts {
  display: flex;
  color: white;
  height: 100%;
  width: 100%;
}
