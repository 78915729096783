.how-to-help__block {
  max-width: $page-max-width;
  margin: 70px auto 60px auto;

  @include breakpoint-mobile {
    margin: 0px auto;
  }
}

.how-to-help__main-title {
  text-align: center;
  font-family: $font-family-roboto;
  margin: 0px auto;
}

.how-to-help__cards-box {
  transform: translateY(-5px);
  display: flex;
  a {
    color: black;
  }

  @include breakpoint-mobile {
    flex-direction: column;
  }
}

.how-to-help__card-wrapper {
  flex-grow: 1;
  flex-basis: 0;
  display: initial;

  @include breakpoint-mobile {
    margin-top: -40px;
  }
}

.how-to-help__card {
  height: 340px;
  margin: 200px auto 20px auto;
  display: flex;
  flex-direction: column;
  background-color: $color-light-gray;
  font-family: Roboto, sans-serif;
  max-width: 95%;
  position: relative;
}

.how-to-help__image {
  position: absolute;
  display: block;
  right: 50%;
  flex-grow: 0;
  width: 80%;
  transition: width 300ms;
  margin: 0 auto;
  height: 75%;
  transform: translate(50%, -50%);
  object-fit: cover;
  object-position: top;
}

.how-to-help__text {
  position: absolute;
  top: 50%;
  left: 10%;
  width: 80%;
  flex-grow: 1;
  height: 50%;
  margin: 0 auto;
  margin-bottom: 1rem;
}

.how-to-help__title {
  display: block;
  font-family: $font-family-bebas-neue;
  font-size: 2rem;
  margin-bottom: 1rem;
  text-transform: uppercase;
  white-space: nowrap;
}

.how-to-help__lead {
  font-size: 1rem;
  line-height: 1.15;
  max-height: 5rem;
  overflow: hidden;
}

.how-to-help__card:hover {
  background-color: $color-main;
}

.how-to-help__card:hover .how-to-help__image {
  width: 100%;
}

.how-to-help__card:hover .how-to-help__text {
  color: white;
}

.how-to-help__image-wrapper {
  height: 50%;
}
