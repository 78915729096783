.cookies {
  width: calc(100% - 2rem);
  padding: 1rem;
  position: fixed;
  right: 0;
  bottom: 0;
  margin-right: 1rem;
  margin-left: 1rem;
  margin-bottom: 1rem;
  background-color: $color-light-gray;
  opacity: 0.7;
  box-shadow: $box-shadow;
  max-width: $page-max-width;
  display: none;

  @include breakpoint-mobile {
    margin: 0 0 0 0;
    width: 100%;
    font-size: 0.8rem;
  }
}

.cookies__info-text {
  padding: 1rem 1rem 1rem 1rem;
}

.cookies__close-window {
  cursor: pointer;
}

.cookies__buttons {
  font-size: 0.8rem;
  display: flex;
  justify-content: flex-end;
}

.cookies__close-button {
  padding: 0.5rem;
}

.cookies__more-info {
  padding: 0.5rem;
  text-decoration: underline;
}

.cookies__more-info-text {
  color: $color-black;
}
