$font-weight-light: 300;
$font-weight-regular: 400;
$font-weight-bold: 700;

@font-face {
  font-family: "Antonio";
  src: url("@/fonts/Antonio-Light.ttf") format("truetype");
  font-weight: $font-weight-light;
  font-style: normal;
}

@font-face {
  font-family: "Antonio";
  src: url("@/fonts/Antonio-Regular.ttf") format("truetype");
  font-weight: $font-weight-regular;
  font-style: normal;
}

@font-face {
  font-family: "Antonio";
  src: url("@/fonts/Antonio-Bold.ttf") format("truetype");
  font-weight: $font-weight-bold;
  font-style: normal;
}

@font-face {
  font-family: "Teko";
  src: url("@/fonts/Teko-Bold.ttf") format("truetype");
  font-weight: $font-weight-bold;
  font-style: normal;
}

@font-face {
  font-family: "Teko";
  src: url("@/fonts/Teko-Regular.ttf") format("truetype");
  font-weight: $font-weight-regular;
  font-style: normal;
}

@font-face {
  font-family: "BebasNeue";
  src: url("@/fonts/BebasNeue-Regular.ttf") format("truetype");
  font-weight: $font-weight-regular;
  font-style: normal;
}

@font-face {
  font-family: "BebasNeue";
  src: url("@/fonts/BebasNeue-Light.ttf") format("truetype");
  font-weight: $font-weight-light;
  font-style: normal;
}

@font-face {
  font-family: "Roboto";
  src: url("@/fonts/Roboto-Regular.ttf") format("truetype");
  font-weight: $font-weight-regular;
  font-style: normal;
}

@font-face {
  font-family: "Roboto";
  src: url("@/fonts/Roboto-Italic.woff2") format("woff2"),
    url("@/fonts/Roboto-Italic.woff") format("woff"),
    url("@/fonts/Roboto-Italic.ttf") format("truetype");
  font-weight: $font-weight-regular;
  font-style: italic;
}

@font-face {
  font-family: "Roboto";
  src: url("@/fonts/Roboto-BlackItalic.ttf") format("truetype");
  font-weight: $font-weight-bold;
  font-style: italic;
}

@font-face {
  font-family: "Roboto";
  src: url("@/fonts/Roboto-Bold.ttf") format("truetype");
  font-weight: $font-weight-bold;
  font-style: normal;
}

@font-face {
  font-family: "Roboto";
  src: url("@/fonts/Roboto-Thin.ttf") format("truetype");
  font-weight: $font-weight-light;
  font-style: normal;
}

@font-face {
  font-family: "Lato";
  src: url("@/fonts/Lato-Regular.ttf") format("truetype");
  font-weight: $font-weight-regular;
  font-style: normal;
}

$font-family-antonio: Antonio, sans-serif;
$font-family-roboto: Roboto, sans-serif;
$font-family-lato: Lato, sans-serif;
$font-family-bebas-neue: BebasNeue, sans-serif;

$font-primary: $font-family-roboto;
$font-secondary: $font-family-bebas-neue;
