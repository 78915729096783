$ticket-width: 1440px;
$ticket-height: 530px;

.gratification-ticket {
  display: block;
  margin: 0px auto;
  object-fit: cover;
  background-size: contain;
  height: $ticket-height;
  width: $ticket-width;
  position: relative;
  transform: translateY(-135px);

  @include breakpoint-screen-medium {
    width: math.div($ticket-width, 2);
    height: math.div($ticket-height, 2);
  }

  @include breakpoint-mobile {
    width: math.div($ticket-width, 3);
    height: math.div($ticket-height, 3);
  }

  @include breakpoint-mobile-narrow {
    width: math.div($ticket-width, 5);
    height: math.div($ticket-height, 5);
  }

  @include print {
    transform: translateY(0px);
    background-color: white;
    background-repeat: no-repeat;
  }
}

.gratification-ticket__title {
  font-size: 3.5rem;
  text-transform: uppercase;
  font-family: $font-family-antonio;
  line-height: 1.5;

  @include breakpoint-mobile {
    font-size: 2rem;
  }
}

.gratification-ticket__text {
  font-size: 1.5rem;
  margin-top: 2rem;

  @include breakpoint-mobile {
    font-size: 1rem;
  }
}

.gratification-ticket__ticket-name {
  font-family: $font-family-roboto;
  position: absolute;
  top: 24%;
  left: 30%;
  font-size: 2rem;

  @include breakpoint-screen-medium {
    font-size: 12px;
  }

  @include breakpoint-mobile {
    font-size: 9px;
  }
}

.gratification-ticket__ticket-title {
  position: absolute;
  font-family: $font-family-roboto;
  text-transform: uppercase;
  color: #fff;
  font-size: 32px;
  top: 3.5%;
  left: 25.5%;
  font-weight: 700;

  @include breakpoint-screen-medium {
    font-size: 16px;
  }

  @include breakpoint-mobile {
    font-size: 12px;
  }
  @include breakpoint-mobile-narrow {
    font-size: 7px;
  }
}

.gratification-ticket__ticket-info {
  position: absolute;
  font-family: $font-family-roboto;
  font-size: 1rem;
  font-weight: 700;
  top: 38%;
  left: 30%;
  max-width: 40%;
  line-height: 1.3;

  @include breakpoint-screen-medium {
    font-size: 8px;
    line-height: 1;
  }

  @include breakpoint-mobile {
    font-size: 6px;
    line-height: 1;
  }

  @include breakpoint-mobile-narrow {
    font-size: 4px;
  }
}

.gratification-ticket__ticket-footer {
  position: absolute;
  font-family: $font-family-roboto;
  color: #fff;
  font-size: 1rem;
  top: 90%;
  left: 25%;

  @include breakpoint-screen-medium {
    font-size: 8px;
  }

  @include breakpoint-mobile {
    font-size: 6px;
  }

  @include breakpoint-mobile-narrow {
    font-size: 4px;
    top: 89%;
  }
}

.gratification-ticket__ticket-photo {
  object-fit: cover;
  position: absolute;
  width: 24.2%;
  height: 74%;
  left: 0px;
  top: 13%;
}

.gratification-ticket__share {
  display: flex;
  justify-content: space-between;
  margin: 3rem auto;
  padding-top: 1rem;
  width: 700px;
  border-top: 2px grey solid;
  color: $color-main;
  transform: translateY(-120px);

  @include breakpoint-mobile {
    width: 90%;
  }

  @include print {
    display: none;
  }
}

.gratification-ticket__social-share {
  display: flex;
  justify-content: space-around;
}

.gratification-ticket__share-print {
  text-transform: uppercase;
  cursor: pointer;
}

.gratification-ticket__social-share-text {
  text-transform: uppercase;
}

.gratification-ticket__social-share-icon {
  color: $color-main;
  transform: scale(1.5);
  margin-left: 2rem;
}
