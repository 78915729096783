.rules {
  max-width: $page-max-width;
  width: 75%;
  margin: 50px auto;

  @include breakpoint-mobile {
    width: 90%;
  }
}

.rules__main-title {
  text-align: center;
  font-size: 2rem;
  margin-top: 2rem;
  margin-bottom: 2rem;
}

.rules__section {
  margin-bottom: 3rem;
}

.rules__section-title {
  font-size: 1.5rem;
  font-weight: 500;
  text-align: center;
  margin-bottom: 1rem;
}
