.team__container {
  max-width: $page-max-width;
  margin: 50px auto;
}

.team__title {
  font-family: $font-family-bebas-neue;
  text-align: center;
  text-transform: uppercase;
  margin-bottom: 80px;

  @include breakpoint-mobile {
    font-size: 1.5rem;
  }
}

.team__members {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  @include breakpoint-mobile {
    justify-content: space-around;
  }
}

.team__member {
  display: flex;
  align-items: baseline;
  justify-content: center;
  width: 24%;
  position: relative;
  background-color: $color-light-gray;
  margin: 50px 0px;
  height: 220px;
  padding: 0px 20px;

  @include breakpoint-mobile {
    width: 45%;
  }

  @include breakpoint-mobile-narrow {
    width: 90%;
  }
}

.team__member-link {
  color: black;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.team__member-image {
  width: 110px;
  height: 110px;
  object-fit: cover;
  transform: translateY(-50%);
}

.team__member-name {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  margin: 0;
  font-family: $font-family-bebas-neue;
  font-size: 1.5rem;
}

.team__member-description {
  height: 70px;
  transform: translateY(25px);
  text-align: center;
}
