$mobile-width: 768px;
$mobile-narrow-width: 500px;
$screen-large-width: 1101px;
$screen-xlarge-width: 1600px;
$screen-1000px: 1000px;

@mixin breakpoint-mobile {
  @media (max-width: #{$mobile-width}) {
    @content;
  }
}

@mixin breakpoint-mobile-narrow {
  @media (max-width: #{$mobile-narrow-width}) {
    @content;
  }
}

@mixin breakpoint-tablet {
  @media (min-width: #{$mobile-width}) {
    @content;
  }
}

@mixin breakpoint-screen-small {
  @media (max-width: #{$screen-1000px}) {
    @content;
  }
}

@mixin breakpoint-screen-medium {
  @media (min-width: #{$mobile-width + 1px}) and (max-width: #{$screen-large-width - 1px}) {
    @content;
  }
}

@mixin breakpoint-screen-large {
  @media (min-width: #{$screen-large-width}) {
    @content;
  }
}

@mixin breakpoint-screen-xlarge {
  @media (min-width: #{$screen-xlarge-width}) {
    @content;
  }
}

@mixin print {
  @media print {
    @content;
  }
}
