$passport-width: 1440px * 0.7;
$passport-height: 974px * 0.7;

.gratification-passport {
  margin: 0px auto;
  display: block;
  object-fit: cover;
  background-size: contain;
  background-repeat: no-repeat;
  width: $passport-width;
  height: $passport-height;
  position: relative;
  transform: translateY(-135px);

  @include breakpoint-screen-medium {
    width: math.div($passport-width, 2);
    height: math.div($passport-height, 2);
  }

  @include breakpoint-mobile {
    width: math.div($passport-width, 3);
    height: math.div($passport-height, 3);
  }

  @include breakpoint-mobile-narrow {
    width: math.div($passport-width, 5);
    height: math.div($passport-height, 5);
  }

  @include print {
    transform: translateY(0px);
  }
}

.gratification-passport__title {
  font-size: 3.5rem;
  text-transform: uppercase;
  font-family: $font-family-antonio;
  line-height: 1.5;

  @include breakpoint-mobile {
    font-size: 2rem;
  }
}

.gratification-passport__text {
  font-size: 1.5rem;
  margin-top: 2rem;
}

%gratification-passport__user-data {
  font-family: $font-family-roboto;
  position: absolute;
  left: 28.25%;
  font-size: 1rem;

  @include breakpoint-screen-medium {
    font-size: 10px;
  }

  @include breakpoint-mobile {
    font-size: 7px;
  }

  @include breakpoint-mobile-narrow {
    font-size: 4px;
  }
}

.gratification-passport__first-name {
  @extend %gratification-passport__user-data;

  top: 28%;
}

.gratification-passport__last-name {
  @extend %gratification-passport__user-data;

  top: 22.25%;
}

.gratification-passport__country {
  @extend %gratification-passport__user-data;

  top: 33.25%;
}

.gratification-passport__letter-name {
  font-family: $font-family-roboto;
  position: absolute;
  top: 50%;
  left: 6.5%;
  font-size: 1.5rem;
  color: $color-main;

  @include breakpoint-screen-medium {
    font-size: 12px;
  }

  @include breakpoint-mobile {
    font-size: 9px;
  }

  @include breakpoint-mobile-narrow {
    font-size: 6px;
  }
}

.gratification-passport__photo-user {
  object-fit: cover;
  position: absolute;
  width: 18%;
  height: 32.5%;
  left: 6.5%;
  top: 14.5%;
}

.gratification-passport__share {
  display: flex;
  justify-content: space-between;
  margin: 3rem auto;
  padding-top: 1rem;
  width: 700px;
  border-top: 2px grey solid;
  color: $color-main;
  transform: translateY(-120px);

  @include breakpoint-mobile {
    width: 90%;
  }

  @include print {
    display: none;
  }
}

.gratification-passport__social-share {
  display: flex;
  justify-content: space-around;
}

.gratification-passport__share-print {
  text-transform: uppercase;
  cursor: pointer;
}

.gratification-passport__social-share-icon {
  color: $color-main;
  transform: scale(1.5);
  margin-left: 2rem;
}

.gratification-passport__social-share-text {
  text-transform: uppercase;
}
