* {
  box-sizing: border-box;
  // print background images on print
  -webkit-print-color-adjust: exact !important;
  color-adjust: exact !important;
}

html {
  height: 100%;
}

body {
  font-family: $font-primary;
  height: 100%;
  display: flex;
  flex-direction: column;

  > * {
    width: 100%;
  }
}

.page-content {
  flex-grow: 1;
}

p {
  font-weight: 400;
}

a {
  text-decoration: none;
}

a[href^="mailto:"] {
  color: black;
}

button {
  font-size: 100%;
  cursor: pointer;
}
