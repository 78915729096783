.radio-button {
  cursor: pointer;
  margin-right: 5px;
  flex-grow: 1;

  &:last-child {
    margin-right: 0px;
  }

  &:first-child {
    margin-left: 0px;
  }
}

.radio-button__button {
  border: 2px solid;
  padding: 1rem;
  display: inline-block;
  text-transform: uppercase;
  font-family: $font-family-roboto;
  font-weight: bold;
  box-sizing: border-box;
  width: 100%;
  text-align: center;

  @include breakpoint-mobile {
    margin-bottom: 1rem;
  }
}

.radio-button__button--orange {
  border-color: $color-main;
  color: $color-main;
}

// hide default radio
.radio-button__input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

// custom radios that are preceeded by a checked input
.radio-button__input:checked ~ .radio-button__button {
  background-color: $color-main;
  border-color: $color-main;
  color: white;
}

// custom radios that are preceeded by a disabled input
.radio-button__input:disabled ~ .radio-button__button {
  border-color: $color-light-gray;
  color: $color-light-gray;
}
