$user-full-name-font-size: 1.5rem;
$user-full-name-font-size-mobile: 0.8rem;
$friend-horizontal-margin: 5px;
$friend-width: math.div(100%, 4);
$friend-width-mobile: math.div(100%, 3);
$friend-width-tablet: math.div(100%, 4);

.friends {
  margin: 40px auto 80px auto;
  max-width: $page-max-width;

  @include breakpoint-mobile {
    margin: 50px 0px 3rem 0px;
  }
}

.friends__list {
  transform: translateY(-40px);
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
}

.friends__title {
  text-transform: uppercase;
  text-align: center;
  font-family: $font-family-bebas-neue;

  @include breakpoint-mobile {
    font-size: 1.75rem;
    margin-bottom: 2.5rem;
  }
}

.friends__friend {
  width: calc(#{$friend-width} - #{$friend-horizontal-margin * 2});
  margin: 60px $friend-horizontal-margin;
  height: 100px;
  line-height: 1.4;

  @include breakpoint-tablet {
    width: calc(#{$friend-width-tablet} - #{$friend-horizontal-margin * 2});
  }

  @include breakpoint-mobile {
    width: calc(#{$friend-width-mobile} - #{$friend-horizontal-margin * 2});
    margin: 15px $friend-horizontal-margin;
  }
}

.friends__friend-avatar {
  width: 100px;
  height: 100px;
  background-color: $color-main;
  object-fit: cover;
  margin: 0 auto;
  display: block;
  transform: translateY(50%);
  background-repeat: no-repeat;

  @include breakpoint-mobile {
    width: 55px;
    height: 55px;
  }
}

.friends__friend-avatar--empty {
  object-fit: scale-down;
  border: 2px solid $color-main;
  background-color: white;
}

.friends__friend-user-full-name {
  height: 150px;
  display: block;
  color: black;
  background-color: $color-light-gray;
  text-align: center;
  padding-top: calc(15% + #{$user-full-name-font-size});
  padding-bottom: 15%;
  font-family: $font-family-bebas-neue;
  font-size: $user-full-name-font-size;
  text-transform: uppercase;

  @include breakpoint-mobile {
    font-size: $user-full-name-font-size-mobile;
    padding-top: calc(15% + #{$user-full-name-font-size-mobile});
    height: 80px;
  }
}

.friends__friend-user-full-name--linked {
  text-decoration: underline;
}

.friends__show-more-button {
  background-color: $color-main;
  color: white;
  padding: 1rem 2rem;
  display: block;
  margin: 4.5rem auto;
  font-family: $font-family-antonio;
  text-transform: uppercase;
  letter-spacing: 2px;

  @include breakpoint-mobile {
    margin: 0 auto;
  }
}
