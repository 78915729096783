.right-image__block {
  background-color: $color-light-gray;
  width: 100%;
}

.right-image__content {
  max-width: $page-max-width;
  display: flex;
  margin: 0 auto;
  align-items: center;

  @include breakpoint-screen-small {
    flex-direction: column;
  }
}

.right-image__text {
  margin-left: 3rem;
  font-size: 7rem;
  font-family: $font-family-bebas-neue;

  @include breakpoint-screen-small {
    font-size: 3rem;
    margin: 1rem;
  }
}

.right-image__image {
  width: 100%;
  max-height: 450px;
  object-fit: contain;
}
